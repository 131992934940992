import { Typography } from "@mui/material";
import ContainerStyled from "../../components/common/transactions-container.styled.ts";
import SwapWidget from "../../components/swap-widget";
import styles from "../styles.module.css";
import SwapContainerStyled from "./swap-container.styled.ts";

const Swap: React.FunctionComponent = () => {
  return (
    <ContainerStyled>
      <Typography variant="h2" className={styles["page-title"]}>
        Swap
      </Typography>
      <SwapContainerStyled>
        <SwapWidget />
      </SwapContainerStyled>
    </ContainerStyled>
  );
};

export default Swap;
