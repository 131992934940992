import styled from 'styled-components';
import getMeasureValue from '../../helpers/get-measure-value.ts';

type ContainerStyledProps = {
  width?: number | string;
  maxWidth?: number | string;
};

const ContainerStyled = styled.div<ContainerStyledProps>`
  outline: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 15px 10px;
  @media (min-width: 767px) {
    ${(props: ContainerStyledProps) => (props.width !== undefined ? `width: ${getMeasureValue(props.width)};` : '')}
    ${(props: ContainerStyledProps) => (props.maxWidth !== undefined ? `max-width: ${getMeasureValue(props.maxWidth)};` : '')}    
  }
  @media (max-width: 767px) {
    margin: 5px;
    padding: 5px;
    width: calc(100dvw - 20px);
  }
}
`;

export default ContainerStyled;
