import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Routes from './routes.tsx';
import theme from './theme/theme.ts';

const RootComponent: React.FunctionComponent = () => {
  const queryClient = new QueryClient();

  Sentry.init({
    dsn: 'https://20fba3f9793dd52b2df3f8eafda1899f@o4508791229579264.ingest.de.sentry.io/4508791245701200',
    integrations: [],
  });

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <TonConnectUIProvider manifestUrl="https://mybit.me/tonconnect-manifest.json">
            <>
              <Routes />
              {import.meta.env.MODE !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
            </>
          </TonConnectUIProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
};

createRoot(document.querySelector('#root')!).render(<RootComponent />);
