import { Typography } from "@mui/material";
import FlexContainer from "../../components/common/flex-container";
import ContainerStyled from "../../components/common/transactions-container.styled.ts";
import NftRewardComponent from "../../components/nft-reward";
import type TokenMetadataItem from "../../components/tokens/types/token-metadata-item.ts";
import styles from "../styles.module.css";

type NftRewardProps = {
  tokensMetadata: TokenMetadataItem[];
  tokensSymbols: string[];
};

const NftReward: React.FunctionComponent<NftRewardProps> = (props: NftRewardProps) => {
  return (
    <ContainerStyled>
      <Typography variant="h2" className={styles["page-title"]}>
        Nft reward
      </Typography>
      <FlexContainer overflowX="auto">
        <NftRewardComponent tokensMetadata={props.tokensMetadata} tokensSymbols={props.tokensSymbols} />
      </FlexContainer>
    </ContainerStyled>
  );
};

export default NftReward;
