import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import round from 'lodash.round';
import formatNumber from "../../common/format-number";
import WidgetData from "../types/widget-data.ts";
import TokenPnlDataMapped from "../types/token-pnl-data-mapped.ts";
import orderby from 'lodash.orderby';


dayjs.extend(customParseFormat);

function tokenPnlDataMapping(dataTransactions: Array<any>): TokenPnlDataMapped {
  if (dataTransactions && dataTransactions?.length >= 0) {

    const widgetDataReports: Array<WidgetData> = [];

    const transactions: Array<any> = dataTransactions?.map((transactionElement: any) => {

      const statistics2: any = transactionElement.statistics2;

      if (statistics2) {
        widgetDataReports.push({
          id: String(Math.random()),
          account: transactionElement?.account || ' ',
          buys: statistics2.buys,
          dollarsForOneToken: statistics2.dollars_for_one_token,
          innerTransfers: statistics2.inner_transfers,
          received: statistics2.received,
          sent: statistics2.sent,
          sells: statistics2.sells,
          transactions: statistics2.transactions,
          reportToken: statistics2?.report_token,
          reportDate: statistics2?.report_date ?? ' ',
          resultInTons: statistics2?.result_in_tons ?? ' ',
          tokensActual: statistics2?.tokens_actual ?? ' ',
          tokensBought: statistics2?.tokens_bought ?? ' ',
          tokensReceived: statistics2?.tokens_received ?? ' ',
          tokensSent: statistics2?.tokens_sent ?? ' ',
          tokensSold: statistics2?.tokens_sold ?? ' ',
          unrealizedPnl: statistics2?.unrealized_pnl ?? ' ',
          tonSpent: statistics2?.ton_spent ?? ' ',
        });
      }

      return transactionElement?.events?.map((eventElement: any) => ({
        date: eventElement.timestamp !== undefined && eventElement.timestamp !== null ? dayjs(eventElement.timestamp).format('YYYY-MM-DD') : null,
        time: eventElement.timestamp !== undefined && eventElement.timestamp !== null ? dayjs(eventElement.timestamp).format('hh:mm:ss A') : null,
        account: transactionElement.account,
        transactionToken: eventElement.transaction_token,
        type: eventElement.type,
        explain: eventElement.explain,
        sentAmount: eventElement.sent_amount !== undefined && eventElement.sent_amount !== null ? formatNumber(round(eventElement.sent_amount, 2)) : null,
        sentToken: eventElement.sent_token,
        receivedAmount: eventElement.received_amount !== undefined && eventElement.received_amount !== null ? formatNumber(round(eventElement.received_amount, 2)) : null,
        receivedToken: eventElement.received_token,
        message: eventElement.message,
        externalAccount: eventElement?.external_account,
        eventId: eventElement.event_id,
      }));

    })?.flat() || [];

    return {
      transactions: orderby(transactions, 'date', 'desc'),
      widgetData: widgetDataReports
    };
  }
  return {
    transactions: null,
    widgetData: null,
  };
}

export default tokenPnlDataMapping;