 // import transactionsData from '../../../__mocks__/transactions.ts' ;
 // import ControllerName from "../../../enums/controller-name.ts";

import {toast} from "react-toastify";
 import {axiosInstance} from "../../../api/client-base.ts";
 import InstanceType from "../../../enums/instance-type.ts";

 export const getTransactions = async (token: string, dateFrom: string | null = null, dateTo: string | null = null, accounts: Array<string>) => {
   // return Promise.resolve({ data: transactionsData});
   const data: any = {
      accounts,
      merge_transactions: true,
      return_transactions: true,
      token_symbol: token,
   };

   if (dateFrom) {
      data.from_date = dateFrom;
      // data.fromTime = '00:00:00.000';
   }
   if (dateTo) {
      data.to_date = dateTo;
      // data.toTime = '23:59:59.999';
   }

   return await axiosInstance({ method: 'POST', url: `${InstanceType.Api}/tools/token-pnl/GetTransactions`, data }).catch((error: any) => {
      toast(error?.message, { type: 'error', draggable: true });
   });

   // return Promise.resolve({ data: {
   //     "request": {
   //       "accounts": [
   //         "UQCT5_IU23zNQL8bvzXvfXXeQobhvyaDpbZ9ciZq8uvJxoPx"
   //       ],
   //       "mergeTransactions": true,
   //       "returnTransactions": true,
   //       "tokenSymbol": "STORM",
   //       "tokenAddress": null,
   //       "fromDate": "2024-11-01",
   //       "fromTime": null,
   //       "toDate": "2025-01-02",
   //       "toTime": null
   //     },
   //     "accounts": [
   //       {
   //         "accountAlias": "A1",
   //         "accountRelation": "OWN",
   //         "account": "UQCT5_IU23zNQL8bvzXvfXXeQobhvyaDpbZ9ciZq8uvJxoPx"
   //       },
   //       {
   //         "accountAlias": null,
   //         "accountRelation": "External recipient",
   //         "account": "EQDaC-uD4Q0GkTnG9lXqPb5HKgyUhlzcQVJOHJDnDyVNUdik"
   //       }
   //     ],
   //     "results": [
   //       {
   //         "account": "UQCT5_IU23zNQL8bvzXvfXXeQobhvyaDpbZ9ciZq8uvJxoPx",
   //         "events": [
   //           {
   //             "account": "A1",
   //             "timestamp": "2024-12-30T18:54:41Z",
   //             "eventId": "2e1ac316df0558534c50354e440e75b6728973146544bf72c7143ea9848ca712",
   //             "message": null,
   //             "sentToken": "TON",
   //             "sentAmount": 5,
   //             "receivedToken": "STORM",
   //             "receivedAmount": 909.918883941,
   //             "transactionToken": "STORM",
   //             "type": "Buy",
   //             "explain": "-5.00 TON -> +909.92 STORM"
   //           },
   //           {
   //             "account": "A1",
   //             "timestamp": "2024-12-31T07:14:46Z",
   //             "eventId": "f866f2db7679a58dbad9e8cd43a8de69bcceeba4402cd34e0858e94e943f2b8b",
   //             "message": null,
   //             "sentToken": "TON",
   //             "sentAmount": 2,
   //             "receivedToken": "STORM",
   //             "receivedAmount": 369.778964355,
   //             "transactionToken": "STORM",
   //             "type": "Buy",
   //             "explain": "-2.00 TON -> +369.78 STORM"
   //           },
   //           {
   //             "account": "A1",
   //             "timestamp": "2024-12-31T14:32:47Z",
   //             "eventId": "5e75cf8a6d68fa20e1f674f003aa693da27955eab0518bbef2e614710a658768",
   //             "message": "Call: 0xe3a06989",
   //             "sentToken": "STORM",
   //             "sentAmount": 1200,
   //             "externalAccount": "EQDaC-uD4Q0GkTnG9lXqPb5HKgyUhlzcQVJOHJDnDyVNUdik",
   //             "transactionToken": "STORM",
   //             "type": "Sent out",
   //             "explain": "Sent out 1 200.00 STORM"
   //           },
   //           {
   //             "account": "A1",
   //             "timestamp": "2024-12-31T14:33:13Z",
   //             "eventId": "f829ff1ecf32d1876773464f9f71fd48795c4e84281293e0aae77667b656d6ac",
   //             "message": "Call: 0xe3a06989",
   //             "sentToken": "STORM",
   //             "sentAmount": 1200,
   //             "externalAccount": "EQDaC-uD4Q0GkTnG9lXqPb5HKgyUhlzcQVJOHJDnDyVNUdik",
   //             "transactionToken": "STORM",
   //             "type": "Sent out",
   //             "explain": "Sent out 1 200.00 STORM"
   //           }
   //         ],
   //         "statistics2": {
   //           "created By": "https://t.me/MyBitMoney (https://t.me/Alexey_Petryashev)",
   //           "report Date": "2025-01-03T10:04:24.6412868+00:00",
   //           "report Token": "STORM",
   //           "ton spent": -7,
   //           "tokens bought": 1279.697848296,
   //           "tokens sold": -0,
   //           "tokens sent": -2400,
   //           "tokens received": 0,
   //           "tokens actual": -1120.302151704,
   //           "result in Tons (realized PNL)": -7,
   //           "unrealized PNL (if you sell tokens)": "",
   //           "buys": 2,
   //           "sells": 0,
   //           "innerTransfers": 0,
   //           "sent": 2,
   //           "received": 0,
   //           "transactions": 4,
   //           "tokensForOneTonMean": 182.81397832800002,
   //           "dollarsForOneToken": 0.030989698117259823
   //         },
   //         "statistics": {
   //           "tokenSymbol": "STORM",
   //           "tonAmountSpent": -7,
   //           "tokenAmountBought": 1279.697848296,
   //           "tokenAmountSold": -0,
   //           "tokenAmountSent": -2400,
   //           "tokenAmountReceived": 0,
   //           "resultTonAmount": -7,
   //           "resultUsdtAmount": -39.65745,
   //           "resultTokenAmount": -1120.302151704,
   //           "buys": 2,
   //           "sells": 0,
   //           "innerTransfers": 0,
   //           "received": 0,
   //           "sent": 2,
   //           "transactions": 4,
   //           "tokensForOneTonMean": 182.81397832800002,
   //           "dollarsForOneToken": 0.030989698117259823
   //         }
   //       }
   //     ]
   //   }});
};