import { useEffect, useState } from 'react';
import MainHeader from '../main-header';
import Sidebar from '../sidebar';
import MainContainerSectionStyled from './main-container-section.styled.ts';
import MainLayoutContainerStyled from './main-layout-container.styled.ts';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout: React.FunctionComponent<MainLayoutProps> = (props: MainLayoutProps) => {
  const [isVisibleMainMenu, setIsVisibleMainMenu] = useState<boolean>(true);
  const [isMobileResolution, setIsMobileResolution] = useState<boolean | undefined>(undefined);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const onWindowResize = (evt: any) => {
      const window = evt.target;
      setInnerWidth(window.innerWidth);
      setIsVisibleMainMenu(true);
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  useEffect(() => {
    if (innerWidth > 767) {
      setIsMobileResolution(false);
      setIsVisibleMainMenu(true);
    } else {
      setIsMobileResolution(true);
      setIsVisibleMainMenu(false);
    }
  }, [innerWidth]);

  const onMainMenuVisibleChange = () => {
    if (innerWidth < 767) {
      setIsVisibleMainMenu((isVisibleMainMenu: boolean) => !isVisibleMainMenu);
    }
  };

  return (
    <MainContainerSectionStyled>
      <MainHeader
        onMainMenuVisibleChange={onMainMenuVisibleChange}
        isVisibleMainMenu={isVisibleMainMenu}
        isMobileResolution={isMobileResolution}
      />
      <MainLayoutContainerStyled>
        <Sidebar isVisibleMainMenu={isVisibleMainMenu} onMainMenuVisibleChange={onMainMenuVisibleChange} />
        {props.children}
      </MainLayoutContainerStyled>
    </MainContainerSectionStyled>
  );
};

export default MainLayout;
