import styled from 'styled-components';

const FormInputsContainerStyled = styled.div`
  display: grid;
  margin-bottom: 20px;
  gap: 10px;
  grid-template-columns: 120px auto;
`;

export default FormInputsContainerStyled;
