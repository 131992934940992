import styled from "styled-components";
import getMeasureValue from "../../../helpers/get-measure-value.ts";
import FlexContainerProps from "./types/flex-container-props";

const FlexContainer = styled.div<FlexContainerProps>`
  ${(props: FlexContainerProps) => `
    display: flex;
    justify-content: ${props.justifyContent || "flex-start"};
    font-size: ${getMeasureValue(props?.fontSize, "inherit")};
    ${props?.width !== null && props?.width !== undefined ? `width: ${getMeasureValue(props.width)};` : ""}
    ${props?.height !== null && props?.height !== undefined ? `height: ${getMeasureValue(props?.height)};` : ""}
    ${props?.margin !== null && props?.margin !== undefined ? `margin: ${getMeasureValue(props.margin)};` : ""}
    ${props?.marginTop !== null && props?.marginTop !== undefined ? `margin-top: ${getMeasureValue(props.margin)};` : ""}
    ${props?.marginBottom !== null && props?.marginBottom !== undefined ? `margin-bottom: ${getMeasureValue(props.margin)};` : ""}
    ${props?.marginLeft !== null && props?.marginLeft !== undefined ? `margin-left: ${getMeasureValue(props.margin)};` : ""}
    ${props?.marginRight !== null && props?.marginRight !== undefined ? `margin-right: ${getMeasureValue(props.margin)};` : ""}
    ${props?.padding !== null && props?.padding !== undefined ? `padding: ${getMeasureValue(props.padding)};` : ""}
    ${props?.backgroundColor ? `background-color: ${props.backgroundColor};` : ""}
    ${props?.borderRadius ? `border-radius: ${props.borderRadius};` : ""}
    ${props?.overflow ? `overflow: ${props.overflow};` : ""}
    ${props?.overflowX ? `overflow-x: ${props.overflowX};` : ""}
    ${props?.minWidth ? `min-width: ${getMeasureValue(props.minWidth)};` : ""}
    ${props?.maxWidth ? `max-width: ${getMeasureValue(props.maxWidth)};` : ""}
    ${props?.flexDirection ? `flex-direction: ${props.flexDirection};` : ""}
    ${props?.alignItems ? `align-items: ${props.alignItems};` : ""}
    ${props?.alignSelf ? `align-self: ${props.alignSelf};` : ""}
    ${props?.gap !== null && props?.gap !== undefined ? `gap: ${getMeasureValue(props.gap)};` : ""}
    ${props?.flexWrap ? `flex-wrap: ${props.flexWrap};` : ""}
    ${props?.borderBottom ? `border-bottom: ${props.borderBottom};` : ""}
    ${props?.outline ? `outline: ${props.outline};` : ""}
    ${props?.color ? `color: ${props.color};` : ""}
  `}
`;

export default FlexContainer;
