import { GetApp } from '@mui/icons-material';
import { Checkbox, CircularProgress, FormControlLabel, FormLabel, MenuItem, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import baseStyles from '../../../index.module.css';
import Button from '../../common/button';
import FlexContainer from '../../common/flex-container';
import Select from '../../common/select';
import TextField from '../../common/textfield';
import NameSwitcher from '../../name-switcher';
import SelectTokens from '../../tokens/select-tokens';
import type TokenMetadataItem from '../../tokens/types/token-metadata-item.ts';
import { getNftCollectionMetadata } from '../api';
import OwnerAddressFormat from '../enums/owner-address-format.ts';
import nftRewardCollectionMetadataMapped from '../mapping/nft-reward-collection-metadata-mapped.ts';
import type NftCollectionMetadata from '../types/nft-collection-metadata.ts';
import styles from './index.module.css';
import NftRewardFormContainerStyled from './nft-reward-form-container.styled.ts';

type NftRewardFormProps = {
  onInputChange: (name: string, value: string | boolean | undefined) => void;
  collectionAddress: string | undefined;
  distributeAmount: string | undefined;
  distributeToken: string | undefined;
  distributeMessage: string | undefined;
  distributeStrategy: string;
  ownerAddressFormat: string;
  onNftRewardReportDownload: (format: string) => void;
  isLoading: boolean;
  isExcludeOwnerCollection: boolean;
  isExcludeNftSale: boolean;
  tokensSymbols: string[];
  tokensMetadata: TokenMetadataItem[];
};

const NftRewardForm: React.FunctionComponent<NftRewardFormProps> = (props: NftRewardFormProps) => {
  const collectionAddress: string | undefined = props.collectionAddress;
  const distributeAmount: string | undefined = props.distributeAmount;
  const distributeToken: string | undefined = props.distributeToken;
  const distributeMessage: string | undefined = props.distributeMessage;
  const isExcludeOwnerCollection: boolean = props.isExcludeOwnerCollection;
  const isExcludeNftSale: boolean = props.isExcludeNftSale;
  const distributeStrategy: string = props.distributeStrategy;

  const ntfCollectionMetadaatResult = useQuery({
    queryKey: ['nft-reward-collection'],
    queryFn: () => {
      if (collectionAddress) {
        return getNftCollectionMetadata(collectionAddress);
      }
    },
    enabled: false,
  });

  const { data: nftRewardCollectionMetadataData, isLoading }: { data: any; isLoading: boolean } =
    ntfCollectionMetadaatResult;

  const nftMetadataResult: NftCollectionMetadata = nftRewardCollectionMetadataMapped(
    nftRewardCollectionMetadataData?.data,
  );

  useEffect(() => {
    if (collectionAddress) {
      ntfCollectionMetadaatResult.refetch();
    }
  }, [collectionAddress]);

  const onCollectioAddressInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target as HTMLInputElement;
    props.onInputChange(element.name, element.value);
  };

  const onCollectioAddressSelectChange = (option: any) => {
    props.onInputChange(option.name, option?.value?.symbol);
  };

  const onOwnerCollectAddress = (name: string, value: string) => {
    props.onInputChange(name, value);
  };

  const onDistributeStrategyChange = (evt: any) => {
    const element = evt.target as HTMLInputElement;
    props.onInputChange(element.name, element.value);
  };

  const isValidForm: boolean = !!collectionAddress && !!distributeAmount && !!distributeToken;

  const onReportDownloadCsv = () => {
    props.onNftRewardReportDownload('csv');
  };

  const onReportDownloadMarkdown = () => {
    props.onNftRewardReportDownload('markdown');
  };

  const onExcludeOwnerCollectionModeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target as HTMLInputElement;
    props.onInputChange(element.name, element.checked);
  };

  const onExcludeNftSaleModeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target as HTMLInputElement;
    props.onInputChange(element.name, element.checked);
  };

  return (
    <form className={baseStyles.form}>
      <FlexContainer gap={20} margin="20px 0 20px 20px" flexDirection="column" justifyContent="flex-start">
        <NftRewardFormContainerStyled>
          <FlexContainer
            width={100}
            height={100}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            alignSelf="flex-start"
          >
            {isLoading && <CircularProgress />}
            {nftMetadataResult.name && (
              <>
                {nftMetadataResult.name}
                {nftMetadataResult?.imagePreview && (
                  <img
                    src={nftMetadataResult.imagePreview}
                    alt={nftMetadataResult.name}
                    width="100"
                    height="100"
                  />
                )}
              </>
            )}
          </FlexContainer>
          <FlexContainer width="90dvw" maxWidth={800} alignItems="center" gap={20}>
            <TextField
              label="Collection address"
              id="collectionAddress"
              name="collectionAddress"
              variant="outlined"
              onChange={onCollectioAddressInputChange}
              value={collectionAddress}
              isError={!collectionAddress}
            />
            <FlexContainer maxWidth={300} flexDirection="column" alignItems="flex-start">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isExcludeOwnerCollection"
                    onChange={onExcludeOwnerCollectionModeChange}
                    checked={isExcludeOwnerCollection}
                  />
                }
                label="Exclude owner collection"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isExcludeNftSale"
                    onChange={onExcludeNftSaleModeChange}
                    checked={isExcludeNftSale}
                  />
                }
                label="Exclude Nft sale"
              />
            </FlexContainer>
          </FlexContainer>
        </NftRewardFormContainerStyled>
        <FlexContainer justifyContent="center" gap={20}>
          <FlexContainer width={180}>
            <Select
              fullWidth={true}
              name="distributeStrategy"
              label="Distribute strategy"
              value={distributeStrategy}
              onChange={onDistributeStrategyChange}
            >
              <MenuItem value={' '}>None</MenuItem>
              <MenuItem value="SplitForAll">Split for all</MenuItem>
              <MenuItem value="ForOneWeight">For one weight</MenuItem>
              <MenuItem value="ForOneAddress">For one address</MenuItem>
            </Select>
          </FlexContainer>
          <FlexContainer width="initial">
            <TextField
              label="Amount"
              id="distributeAmount"
              name="distributeAmount"
              variant="outlined"
              onChange={onCollectioAddressInputChange}
              value={distributeAmount}
              isError={!distributeAmount}
              textAlign="center"
            />
          </FlexContainer>
          <FlexContainer width="250px">
            {props.tokensMetadata.length > 0 ? (
              <SelectTokens
                id="distribute-token"
                name="distributeToken"
                activeElement={distributeToken}
                tokensMetadata={props.tokensMetadata}
                tokensSymbols={props.tokensSymbols}
                onSelectChange={onCollectioAddressSelectChange}
              />
            ) : (
              <TextField
                label="Token"
                id="distribute-token"
                name="distributeToken"
                variant="outlined"
                onChange={onCollectioAddressInputChange}
                value={distributeToken}
                isError={!distributeToken}
              />
            )}
          </FlexContainer>
          <FlexContainer width="250px">
            <TextField
              label="Message"
              id="distributeMessage"
              name="distributeMessage"
              variant="outlined"
              onChange={onCollectioAddressInputChange}
              value={distributeMessage}
              isError={!distributeMessage}
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer className={styles['form-container']}>
        <FlexContainer maxWidth="250px" justifyContent="flex-start" alignItems="center">
          <FormLabel>
            <strong>Address</strong>
          </FormLabel>
          <FlexContainer width={250} justifyContent="flex-start" alignItems="center" margin="0 0 0 10px">
            <NameSwitcher
              disabled={props.isLoading}
              id="owner-collect-address"
              name="ownerAddressFormat"
              value={props.ownerAddressFormat}
              labelActive={OwnerAddressFormat.Name}
              labelInactive={OwnerAddressFormat.Base64}
              labelInactive2={OwnerAddressFormat.Raw}
              onChange={onOwnerCollectAddress}
            />
          </FlexContainer>
        </FlexContainer>
        <Tooltip title="Download report">
          <Button
            type="button"
            variant="outlined"
            onClick={onReportDownloadCsv}
            disabled={!isValidForm || props.isLoading}
          >
            <GetApp /> Tonkeeper CSV
          </Button>
        </Tooltip>
        <Tooltip title="Download report">
          <Button
            type="button"
            variant="outlined"
            onClick={onReportDownloadMarkdown}
            disabled={!isValidForm || props.isLoading}
          >
            <GetApp /> Markdown
          </Button>
        </Tooltip>
      </FlexContainer>
    </form>
  );
};

export default NftRewardForm;
