import styled from 'styled-components';

const ListContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--primary-font-family);
  font-size: var(--main-font-size);
  @media (max-width: 767px) {
    align-items: center;
    font-size: calc(var(--main-font-size) + 10px);
  }
`;

export default ListContainerStyled;
