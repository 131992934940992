import orderBy from 'lodash.orderby';
import round from 'lodash.round';
import type TokenMetadataItem from '../types/token-metadata-item.ts';

function tokensMetadataMapping(tokenMetadataElements: any[]): {
  tokensMetadata: TokenMetadataItem[];
  tokensSymbols: string[];
} {
  const tokensMetadataParsed: TokenMetadataItem[] = [];
  const tokensSymbolSet: Set<string> = new Set();

  for (const tokenElement of tokenMetadataElements) {
    if (!tokensSymbolSet.has(tokenElement.token_symbol)) {
      tokensSymbolSet.add(tokenElement.token_symbol);

      tokensMetadataParsed.push({
        address: tokenElement?.token_address || null,
        symbol: tokenElement.token_symbol,
        name: tokenElement.token_name,
        image: tokenElement.token_image,
        balance:
          tokenElement?.token_balance !== null && tokenElement?.token_balance !== undefined
            ? round(tokenElement.token_balance, 2)
            : null,
        balanceUsd:
          tokenElement?.token_balance_usd !== null && tokenElement?.token_balance_usd !== undefined
            ? round(tokenElement.token_balance_usd, 2)
            : null,
        balanceTon:
          tokenElement?.token_balance_ton !== null && tokenElement?.token_balance_ton !== undefined
            ? round(tokenElement.token_balance_ton, 2)
            : null,
      });
    }
  }

  return {
    tokensMetadata: orderBy(tokensMetadataParsed, 'balanceUsd', 'desc'),
    tokensSymbols: Array.from(tokensSymbolSet),
  };
}

export default tokensMetadataMapping;
