import MuiOutlinedInput from '@mui/material/OutlinedInput';
import type { OutlinedInputProps as MuiOutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import {
  DEFAULT_COMPONENT_HEIGHT,
  DEFAULT_COMPONENT_WIDTH,
  DEFAULT_INPUT_DESKTOP_PADDING,
  DEFAULT_INPUT_MOBILE_PADDING,
} from '../../../constants';

type OutlinedInputProps = MuiOutlinedInputProps & {
  width?: string | number;
  height?: string | number;
  isFullWidth?: boolean;
  fontSize?: string;
  variant?: string;
};

const OutlinedInputStyled = styled(MuiOutlinedInput)<MuiOutlinedInputProps>();

const OutlinedInput: React.FunctionComponent<OutlinedInputProps> = (props: OutlinedInputProps) => {
  return (
    <OutlinedInputStyled
      {...props}
      sx={{
        width: props?.width ?? DEFAULT_COMPONENT_WIDTH,
        height: props?.height ?? DEFAULT_COMPONENT_HEIGHT,
        boxSizing: 'border-box',
        '& input': {
          boxSizing: 'border-box',
          height: props?.height ?? DEFAULT_COMPONENT_HEIGHT,
          padding: DEFAULT_INPUT_DESKTOP_PADDING,
          fontFamily: 'var(--primary-font-family)',
          fontSize: `${props?.fontSize ?? 'var(--main-font-size)'} !important`,
        },
        '@media (max-width: 767px)': {
          borderRadius: '20px',
          '& input': {
            padding: DEFAULT_INPUT_MOBILE_PADDING,
          },
        },
      }}
      fullWidth={props?.isFullWidth}
    />
  );
};

export default OutlinedInput;
