import { List as ListIcon } from '@mui/icons-material';
import { LinearProgress, List, ListItem, useTheme } from '@mui/material';
import type { Theme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation } from '@tanstack/react-router';
import FlexContainer from '../common/flex-container';
import { fetchDataDynamicMenu } from './api';
import ListContainerStyled from './list-container.styled.ts';
import ListItemContainerStyled from './list-item-container.styled.ts';
import dynamicMenuMapped from './mapping/dynamic-menu-mapped.ts';
import SidebarContainerStyled from './sidebar-container.styled.ts';
import SidebarItemHeaderStyled from './sidebar-item-header.styled.ts';
import type DynamicMenuItem from './types/dynamic-menu-item.ts';

type SidebarProps = {
  isVisibleMainMenu: boolean;
  onMainMenuVisibleChange: () => void;
};

const Sidebar: React.FunctionComponent<SidebarProps> = (props: SidebarProps) => {
  const location = useLocation();
  const pathname: string = location.pathname;

  const theme: Theme = useTheme();

  const dynamicMenuDataResult = useQuery({
    queryKey: ['dynamic-main-menu'],
    queryFn: () => fetchDataDynamicMenu(),
    gcTime: 10000000,
  });

  const { data, isFetching }: { data: any; isFetching: boolean } = dynamicMenuDataResult;
  const dynamicMenuData: DynamicMenuItem[] = data?.data ? dynamicMenuMapped(data.data) : [];

  return (
    <SidebarContainerStyled style={{ display: props.isVisibleMainMenu ? 'block' : 'none' }}>
      <FlexContainer height={5} width="100dvw" flexDirection="column">
        {isFetching && <LinearProgress />}
      </FlexContainer>
      {dynamicMenuData?.map((dynamicMenuData: DynamicMenuItem) => (
        <FlexContainer flexDirection="column" key={dynamicMenuData.name}>
          <SidebarItemHeaderStyled
            color={pathname === dynamicMenuData.link ? theme.palette.primary.light : 'transparent'}
          >
            {dynamicMenuData.link ? (
              <Link onClick={props.onMainMenuVisibleChange} to={dynamicMenuData.link}>
                <FlexContainer alignItems="center" gap={5}>
                  {dynamicMenuData?.image ? (
                    <img
                      src={dynamicMenuData.image}
                      height="24"
                      width="24"
                      alt={dynamicMenuData.name || ''}
                    />
                  ) : (
                    <ListIcon />
                  )}{' '}
                  {dynamicMenuData.name}
                </FlexContainer>
              </Link>
            ) : (
              <FlexContainer alignItems="center" gap={5}>
                {dynamicMenuData?.image ? (
                  <img src={dynamicMenuData.image} height="24" width="24" alt={dynamicMenuData.name || ''} />
                ) : (
                  <ListIcon />
                )}{' '}
                {dynamicMenuData.name}
              </FlexContainer>
            )}
          </SidebarItemHeaderStyled>
          {dynamicMenuData?.children?.length && dynamicMenuData.children.length > 0 ? (
            <ListContainerStyled>
              <List>
                {dynamicMenuData?.children?.map((dynamicMenuData: DynamicMenuItem) => (
                  <ListItem key={dynamicMenuData.name}>
                    <ListItemContainerStyled
                      color={pathname === dynamicMenuData.link ? theme.palette.primary.light : 'transparent'}
                    >
                      {dynamicMenuData.link ? (
                        <Link onClick={props.onMainMenuVisibleChange} to={dynamicMenuData.link}>
                          <FlexContainer alignItems="center" gap={5}>
                            {dynamicMenuData?.image && (
                              <img
                                src={dynamicMenuData.image}
                                height="24"
                                width="24"
                                alt={dynamicMenuData.name || ''}
                              />
                            )}{' '}
                            {dynamicMenuData.name}
                          </FlexContainer>
                        </Link>
                      ) : (
                        <FlexContainer alignItems="center" gap={5}>
                          {dynamicMenuData?.image && (
                            <img
                              src={dynamicMenuData.image}
                              height="24"
                              width="24"
                              alt={dynamicMenuData.name || ''}
                            />
                          )}{' '}
                          {dynamicMenuData.name}
                        </FlexContainer>
                      )}
                    </ListItemContainerStyled>
                  </ListItem>
                ))}
              </List>
            </ListContainerStyled>
          ) : null}
        </FlexContainer>
      ))}
      {/*<SidebarItemHeaderStyled>*/}
      {/*  <ListIcon />*/}
      {/*  {'  '}*/}
      {/*  Portfoilo*/}
      {/*</SidebarItemHeaderStyled>*/}
      {/*<ListContainerStyled>*/}
      {/*  <List>*/}
      {/*    <ListItem>*/}
      {/*      <ListItemContainerStyled*/}
      {/*        color={pathname === `/${RouteName.Transactions}` ? theme.palette.primary.light : 'transparent'}*/}
      {/*      >*/}
      {/*        Operations (soon)*/}
      {/*      </ListItemContainerStyled>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</ListContainerStyled>*/}
      {/*<SidebarItemHeaderStyled>*/}
      {/*  <ListIcon />*/}
      {/*  {'  '}*/}
      {/*  Trade*/}
      {/*</SidebarItemHeaderStyled>*/}
      {/*<ListContainerStyled>*/}
      {/*  <List>*/}
      {/*    <ListItem>*/}
      {/*      <ListItemContainerStyled*/}
      {/*        color={pathname === `/${RouteName.Swap}` ? theme.palette.primary.light : 'transparent'}*/}
      {/*      >*/}
      {/*        <Link onClick={props.onMainMenuVisibleChange} to={`/${RouteName.Swap}`}>*/}
      {/*          Swap (beta)*/}
      {/*        </Link>*/}
      {/*      </ListItemContainerStyled>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</ListContainerStyled>*/}
      {/*<SidebarItemHeaderStyled>*/}
      {/*  <ListIcon />*/}
      {/*  {'  '}*/}
      {/*  Ton tools*/}
      {/*</SidebarItemHeaderStyled>*/}
      {/*<ListContainerStyled>*/}
      {/*  <List>*/}
      {/*    <ListItem>*/}
      {/*      <ListItemContainerStyled*/}
      {/*        color={pathname === `/${RouteName.NftReward}` ? theme.palette.primary.light : 'transparent'}*/}
      {/*      >*/}
      {/*        <Link onClick={props.onMainMenuVisibleChange} to={`/${RouteName.NftReward}`}>*/}
      {/*          Nft reward*/}
      {/*        </Link>*/}
      {/*      </ListItemContainerStyled>*/}
      {/*    </ListItem>*/}
      {/*    <ListItem>*/}
      {/*      <ListItemContainerStyled*/}
      {/*        color={pathname === `/${RouteName.TokenPnl}` ? theme.palette.primary.light : 'transparent'}*/}
      {/*      >*/}
      {/*        <Link onClick={props.onMainMenuVisibleChange} to={`/${RouteName.TokenPnl}`}>*/}
      {/*          Token P&L (beta)*/}
      {/*        </Link>*/}
      {/*      </ListItemContainerStyled>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</ListContainerStyled>*/}
      {/*<SidebarItemHeaderStyled>*/}
      {/*  <ListIcon />*/}
      {/*  {'  '}*/}
      {/*  <Link*/}
      {/*    onClick={props.onMainMenuVisibleChange}*/}
      {/*    to="https://github.com/mybit-money/docs"*/}
      {/*    target="_blank"*/}
      {/*  >*/}
      {/*    Documentations*/}
      {/*  </Link>*/}
      {/*</SidebarItemHeaderStyled>*/}
    </SidebarContainerStyled>
  );
};

export default Sidebar;
