enum RouteName {
  Transactions = 'transactions',
  TokenPnl = 'token-pnl',
  Raffle = 'raffle',
  NftReward = 'nft-reward',
  Swap = 'swap',
  Test = 'test',
}

export default RouteName;
