import styled from 'styled-components';

type SidebarContainerStyledProps = {
  style?: any;
};

const SidebarContainerStyled = styled.div<SidebarContainerStyledProps>`
  outline: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  max-width: 200px; 
  padding-left: 15px;
  @media (max-width: 767px) {
    display: none;
    background-color: var(--main-background-color);
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    border-radius: 0;
    width: 100dvw;
    max-width: initial;
  }
`;

export default SidebarContainerStyled;
