import MuiIconButton from '@mui/material/IconButton';
import styles from './index.module.css';
import cls from 'classnames';

type ButtonProps = {
  name?: string
  children: React.ReactNode,
  type?: any,
  onClick?: (evt: any) => void,
  dataName?: number,
  variant?: 'text' | 'outlined'
}

const IconButton: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {

  const btnStyle = cls({
    [styles.button]: true,
    [styles.buttonOutlined]: props.variant === 'outlined',
  });

  return (
    <MuiIconButton name={props?.name} type={props.type} data-name={props.dataName} className={btnStyle} onClick={props?.onClick}>{props.children}</MuiIconButton>
  );
};

export default IconButton;