import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import FlexContainer from '../../components/common/flex-container';
import ContainerStyled from '../../components/common/transactions-container.styled.ts';
import TokenPnlComponent from '../../components/token-pnl';
import { getTransactions } from '../../components/token-pnl/api';
import tokenPnlDataMapping from '../../components/token-pnl/mapping/token-pnl-data-mapping.ts';
import type TokenPnlDataMapped from '../../components/token-pnl/types/token-pnl-data-mapped.ts';
import type TokenMetadataItem from '../../components/tokens/types/token-metadata-item.ts';
import styles from '../styles.module.css';
import type Inputs from './types/inputs.ts';

type TokenPnlProps = {
  tokensMetadata: TokenMetadataItem[];
};

const TokenPnl: React.FunctionComponent<TokenPnlProps> = (props: TokenPnlProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [accountsIds, setAccountsIds] = useState<string>('');

  useEffect(
    () => () => {
      setToken(null);
      setAccountsIds('');
      setDateFrom(null);
      setDateTo(null);
    },
    [],
  );

  const transactionsResponse = useQuery({
    queryKey: ['transactions'],
    queryFn: () => {
      if (token && (dateFrom || dateTo) && accountsIds && accountsIds?.length > 0) {
        const accountsIdsParsed: Array<string> = accountsIds
          ?.split('\n')
          ?.filter((accountElement: string) => accountElement)
          ?.map((accountElement: string) => accountElement?.trim());
        return getTransactions(token, dateFrom, dateTo, accountsIdsParsed);
      }
    },
    enabled: false,
  });

  const { data: transactionsResponseData, isLoading: isLoadingTransactions } = transactionsResponse;

  const transactionsData: any = transactionsResponseData?.data;

  const { transactions, widgetData }: TokenPnlDataMapped = useMemo(
    () => tokenPnlDataMapping(transactionsData?.results),
    [transactionsData?.results],
  );

  const onTransactionsSearch = (params: Inputs) => {
    if (params.token) {
      setToken(params.token);
    }
    if (params?.accountsIds) {
      setAccountsIds(params.accountsIds);
    }
    if (params?.dateFrom !== undefined) {
      setDateFrom(params.dateFrom);
    }
    if (params?.dateTo !== undefined) {
      setDateTo(params.dateTo);
    }
  };

  useEffect(() => {
    transactionsResponse.refetch();
  }, [token, accountsIds, dateFrom, dateTo]);

  return (
    <ContainerStyled>
      <Typography variant="h2" className={styles['page-title']}>
        Token P&L
      </Typography>
      <FlexContainer overflowX="auto">
        <TokenPnlComponent
          onTransactionsSearch={onTransactionsSearch}
          transactions={transactions}
          widgetData={widgetData}
          isLoadingTransactions={isLoadingTransactions}
          tokensMetadata={props.tokensMetadata}
        />
      </FlexContainer>
    </ContainerStyled>
  );
};

export default TokenPnl;
