import { Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import type Option from '../../../types/option.ts';
import type TokenMetadataItem from '../types/token-metadata-item';

type SelectTokensProps = {
  id: string;
  name: string;
  activeElement: unknown;
  tokensMetadata: TokenMetadataItem[];
  tokensSymbols?: string[];
  onSelectChange: (option: Option) => void;
};

const SelectTokens: React.FunctionComponent<SelectTokensProps> = (props: SelectTokensProps) => {
  const [inputValue, setInputValue] = useState('');

  const onOptionChange = (evt: any, newValue: any) => {
    const element = evt.target;
    if (props?.onSelectChange) {
      props.onSelectChange({
        name: props.name,
        label: element.textContent,
        value: newValue,
      });
    }
  };

  const onInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  const activeElement: TokenMetadataItem =
    props.tokensMetadata.find(
      (tokenElement: TokenMetadataItem) => props.activeElement === tokenElement.symbol,
    ) || props.tokensMetadata[0];

  return (
    <Autocomplete
      id={props?.id}
      fullWidth={true}
      value={activeElement}
      inputValue={inputValue}
      options={props.tokensMetadata}
      autoHighlight
      disableClearable={true}
      onInputChange={onInputChange}
      onChange={onOptionChange}
      getOptionLabel={(option: TokenMetadataItem) => option.symbol}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            style={{ fontFamily: 'var(--main-font-color)' }}
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img loading="lazy" width="20" src={option.image} alt={option.name} />
            {option.name} ({option.symbol}) {option.balanceUsd ? `$${option.balanceUsd}` : null}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a token"
          sx={{
            boxSizing: 'border-box',
            height: '40px',
            '& > div': {
              padding: '0 5px !important',
            },
            '& input': {
              boxSizing: 'border-box',
              height: '40px',
              textAlign: 'center !important',
            },
            '& label': {
              transform: 'translate(14px, 12px) scale(1) !important',
            },
            '& label.MuiFormLabel-filled': {
              transform: 'translate(20px, -5px) scale(0.75) !important',
            },
            '& label.Mui-focused': {
              transform: 'translate(20px, -5px) scale(0.75) !important',
            },
            'media (max-width: 768px)': {
              borderRadius: '20px',
              '& > div': {
                padding: '0 16px !important',
              },
            },
          }}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'token', // disable autocomplete and autofill
            },
          }}
        />
      )}
    />
  );
};

export default SelectTokens;
