import TransactionItem from "../types/transaction-item.ts";
import {LinearProgress, Table, TableBody, TableHead, TableRow as TableRowHeader} from "@mui/material";
import TableHeaderCell from "../../common/table-header-cell";
import TransactionsDetailsContainerStyled from "./transactions-details-container.styled.ts";
import TableCell from '../../common/table-cell';
import TableRow from "../../common/table-row";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from "../../common/tooltip";
import OperationTypeSending from "../../enums/operation-type-sending.ts";
import OperationIndication from "../../common/operation-indication";
import OperationType from "../../enums/operation-type.ts";

type TransactionsDetailsProps = {
  transactions: Array<TransactionItem> | null
  isLoadingTransactions: boolean,
}

const TransactionsDetails: React.FunctionComponent<TransactionsDetailsProps> = (props: TransactionsDetailsProps) => {
  return (
    <TransactionsDetailsContainerStyled>
      {props.isLoadingTransactions && <LinearProgress />}
      <Table>
        <TableHead>
          <TableRowHeader>
            <TableHeaderCell width="100px">Date time</TableHeaderCell>
            <TableHeaderCell>Account</TableHeaderCell>
            <TableHeaderCell>Transaction token</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>Explain</TableHeaderCell>
            <TableHeaderCell>Amount</TableHeaderCell>
            <TableHeaderCell>Token</TableHeaderCell>
            <TableHeaderCell>Message</TableHeaderCell>
            <TableHeaderCell>External account</TableHeaderCell>
            <TableHeaderCell>Tx</TableHeaderCell>
          </TableRowHeader>
        </TableHead>
        <TableBody>
          {props.transactions?.map((transactionElement: TransactionItem) => {

            const account: string = transactionElement?.account || '';
            const externalAccount: string = transactionElement?.externalAccount || '';
            const accountShort: string | null = transactionElement?.account ? transactionElement.account.substring(transactionElement.account?.length - 5) : null;
            const externalAccountShort: string | null = transactionElement?.externalAccount ? transactionElement.externalAccount.substring(transactionElement.externalAccount.length - 5) : null;

            return (<TableRow key={transactionElement.eventId}>
              <TableCell>{transactionElement.date} {transactionElement.time}</TableCell>
              <TableCell>{accountShort ? <Tooltip title={account}><span>{accountShort}</span></Tooltip> : null}</TableCell>
              <TableCell>{transactionElement.transactionToken}</TableCell>
              <TableCell><OperationIndication isSendingOperation={OperationTypeSending?.[OperationType?.[transactionElement.type]]}>{transactionElement.type}</OperationIndication></TableCell>
              <TableCell><OperationIndication isSendingOperation={OperationTypeSending?.[OperationType?.[transactionElement.type]]}>{transactionElement.explain}</OperationIndication></TableCell>
              <TableCell><OperationIndication isSendingOperation={OperationTypeSending?.[OperationType?.[transactionElement.type]]}>{transactionElement.sentAmount || transactionElement.receivedAmount}</OperationIndication></TableCell>
              <TableCell><OperationIndication isSendingOperation={OperationTypeSending?.[OperationType?.[transactionElement.type]]}>{transactionElement.sentToken || transactionElement.receivedToken}</OperationIndication></TableCell>
              <TableCell>{transactionElement.message}</TableCell>
              <TableCell>{externalAccountShort ? <Tooltip title={externalAccount}><span>{externalAccountShort}</span></Tooltip> : null}</TableCell>
              <TableCell>{ transactionElement.eventId ? <a href={`https://tonviewer.com/transaction/${transactionElement.eventId}`} target="_blank"><OpenInNewIcon color="primary" /></a> : ''}</TableCell>
            </TableRow>
          );}) ?? null}
        </TableBody>
      </Table>
  </TransactionsDetailsContainerStyled>);
};

export default TransactionsDetails;