import FetchDocumentServer from "../types/fetch-document-server.ts";
import parseBlobFile from "./parse-blob-file.ts";
import { toast } from 'react-toastify';
import {saveAs} from 'file-saver';

function fetchDocumentFromServer(
  promise: any,
  title: string,
  errorMessage: string,
  isLinkShow: boolean,
  isSaveAutomatically: boolean = false,
  fileName?: string
): FetchDocumentServer {
  let statusResponse: number;
  let disposition: string;
  return promise
    .then((response: any) => {
      statusResponse = response.status;
      if (response?.headers.get('content-disposition')) {
        disposition = response.headers.get('content-disposition');
      } else {
        disposition = response.headers?.['content-disposition'];
      }
      statusResponse = response.status;
      if (response.status === 200 || response?.status === 204) {
        return response.blob();
      }
      // const reader = response.body.getReader();
      // const cb = async () => {
      //   const { value } = await reader.read();
      //   dataResponse = JSON.parse(new TextDecoder().decode(value));
      // };
      // cb();
    })
    .then((response: any)=> {
      if ((statusResponse === 200 || statusResponse === 204)) {
        if (!response.size) {
          toast('Get empty file', {type: 'error', draggable: true});
          return { errorMessage };
        }
        const blobFileParsed: any = parseBlobFile(
          response,
          disposition,
          response?.type || 'application/vnd.ms-excel'
        );
        if (isSaveAutomatically) {
          saveAs(blobFileParsed.pathNameToFile, fileName || blobFileParsed.filename);
        }
        if (isLinkShow) {
          return {
            pathNameToFile: blobFileParsed.pathNameToFile,
            fileName: blobFileParsed.fileName,
          };
        }
      } else if (statusResponse !== 500) {
        toast(`${title} ${errorMessage}`, {type: 'error', draggable: true});
        return { errorMessage };
      }
    })
    .catch((error: any) => {
      const errorMessageParsed: string = error?.message?.toString();
      toast(`${title} ${errorMessageParsed}`, {type: 'error', draggable: true});
      toast(`${title} ${errorMessageParsed}`, {type: 'error', draggable: true});
    });
}

export function fetchDocumentFromServerAxios(
  response: any,
  title: string,
  errorMessage: string,
  isLinkShow: boolean,
  isSaveAutomatically: boolean = false,
  fileName?: string
): FetchDocumentServer | void {
  if ((response.status === 200 || response.status === 204)) {
    const blobFileParsed: any = parseBlobFile(
      response.data,
      'filename=report.csv',
      response?.type || 'application/vnd.ms-excel'
    );
    if (isSaveAutomatically) {
      saveAs(blobFileParsed.pathNameToFile, fileName || blobFileParsed.filename);
    }
    if (isLinkShow) {
      return {
        pathNameToFile: blobFileParsed.pathNameToFile,
        fileName: blobFileParsed.fileName,
      };
    }
  } else if (response.status !== 500) {
    toast(`${title} ${errorMessage}`, {type: 'error', draggable: true});
    return { errorMessage };
  }
}


export default fetchDocumentFromServer;