import styled from "styled-components";

const MainTitleContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  @media (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
  }
`;

export default MainTitleContainerStyled;
