import ContainerStyled from "../../components/common/transactions-container.styled.ts";
import {Typography} from "@mui/material";
import styles from "../styles.module.css";

const Raffle: React.FunctionComponent = () => {
  return <ContainerStyled>
    <Typography variant="h2" className={styles['page-title']}>Raffle</Typography>
  </ContainerStyled>;
};

export default Raffle;