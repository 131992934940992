import styled from 'styled-components';
import getMeasureValue from '../../../helpers/get-measure-value.ts';

type LabelStyledProps = {
  fontSize?: number | string;
  color?: string;
  disabled?: boolean;
};

const LabelStyled = styled.label<LabelStyledProps>`
  ${(props: LabelStyledProps) => `
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    font-size: ${getMeasureValue(props?.fontSize, 'inherit')};
    color: ${props.color || 'inherit'};
    padding: 0 5px;
  `}
`;

export default LabelStyled;
