import axios from 'axios';
import { API_LOCATION } from '../constants';

export const controllerSignal = new AbortController();

export const axiosInstance = axios.create({
  baseURL: API_LOCATION,
  timeout: 1000000,
});

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => Promise.reject(error),
);
