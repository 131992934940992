import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useState } from 'react';
import OutlinedInput from '../common/outlined-input';

const Test: React.FunctionComponent = () => {
  const [outlinedInputValue, setOutlinedInputValue] = useState<string | undefined>('some value');

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target as HTMLInputElement;
    setOutlinedInputValue(element.value);
  };

  return (
    <List>
      <ListItem>
        <OutlinedInput
          id="outlined-value"
          name="outlinedValue"
          value={outlinedInputValue}
          onChange={onInputChange}
        />
      </ListItem>
    </List>
  );
};

export default Test;
