import { createTheme } from '@mui/material';

const theme = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      main: '#0A84FF',
      light: '#44A6FF',
    },
    secondary: {
      main: '#f33645',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '--mui-palette-text-primary': 'var(--main-font-color)',
          padding: '8px 10px',
          fontSize: '12px',
          fontFamily: 'var(--primary-font-family)',
          '&.MuiTableCell-head': {
            backgroundColor: 'var(--primary-main-color) !important',
            color: 'var(--main-background-color)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '--mui-palette-primary-main': 'var(--main-font-color)',
          '--mui-palette-error-main': 'var(--secondary-main-color)',
          '--variant-outlinedBorder': 'var(--primary-main-color)',
          '--mui-palette-action-disabled': 'var(--disabled-color)',
          fontFamily: 'var(--primary-font-family)',
          style: {
            color: 'var(--mui-palette-primary-main)',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '--mui-palette-primary-main': 'var(--main-font-color)',
          '--mui-palette-error-main': 'var(--secondary-main-color)',
          '--variant-outlinedBorder': 'var(--primary-main-color)',
          '--mui-palette-action-disabled': 'var(--disabled-color)',
          '--mui-palette-action-active': 'var(--primary-main-color)',
          style: {
            color: 'var(--mui-palette-primary-main)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '--mui-palette-text-secondary': 'var(--main-font-color)',
          '--mui-palette-text-disabled': 'var(--disabled-color)',
          fontSize: '12px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '--mui-palette-common-onBackgroundChannel': 'var(--primary-main-color)',
          '--mui-palette-error-main': 'var(--secondary-main-color)',
          '--mui-palette-text-primary': 'var(--main-font-color)',
          '--mui-palette-action-active': 'var(--primary-main-color)',
          '--mui-palette-text-disabled': 'var(--disabled-color)',
          '--mui-palette-action-disabled': 'var(--disabled-color)',
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--primary-lighter-color)',
            },
            '& .MuiOutlinedInput-input': {
              color: 'var(--primary-lighter-color)',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '--mui-palette-background-paper': 'var(--main-background-color)',
          '--mui-palette-text-primary': 'var(--main-font-color)',
          fontFamily: 'var(--primary-font-family)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--primary-font-family)',
          '&.MuiTypography-h1': {
            fontFamily: 'var(--secondary-font-family)',
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '--mui-palette-background-paper': 'var(--main-background-color)',
          '--mui-palette-text-primary': 'var(--main-font-color)',
          '--mui-palette-error-main': 'var(--secondary-main-color)',
          '--mui-palette-text-secondary': 'var(--main-font-color)',
          '--mui-palette-action-active': 'var(--primary-main-color)',
          '& .Mui-selected': {
            '--mui-palette-primary-main': 'var(--primary-lighter-color)',
            backgroundColor: 'var(--primary-lighter-color) !important',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '--mui-palette-text-secondary': 'var(--main-font-color)',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-label': {
            fontSize: '12px !important',
            fontFamily: 'var(--primary-font-family)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '--mui-palette-text-secondary': 'var(--main-font-color)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: '0 5px !important',
          'media (max-width: 768px)': {
            borderRadius: '20px',
            padding: '0 16px !important',
          },
          '& > fieldset > legend': {
            marginLeft: '10px',
            paddingLeft: '5px',
          },
        },
      },
    },
  },
});

export default theme;
