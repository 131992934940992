import MuiTooltip from "@mui/material/Tooltip";

type TooltipProps = {
  children: any,  //React.ReactElement | number | string | null,
  title: string,
}

const Tooltip: React.FunctionComponent<TooltipProps> = (props: TooltipProps) => {
  return <MuiTooltip  title={props.title}>
    {props.children}
  </MuiTooltip>;
};

export default Tooltip;