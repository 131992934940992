import MuiTableCell from '@mui/material/TableCell';
import CSS from 'csstype';
import styles from './index.module.css';
import {CSSProperties} from "react";

type TableHeaderCellProps = {
  children: React.ReactNode,
  height?: CSS.Property.Height,
  rowSpan?: number | undefined,
  colSpan?: number | undefined,
  width?: CSS.Property.Width,
  className?: string | undefined,
  style?: CSSProperties | undefined,
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined,
}

const TableHeaderCell: React.FunctionComponent<TableHeaderCellProps> = (props: TableHeaderCellProps) => {
  return <MuiTableCell style={props?.style} className={props?.className || styles['table-cell']} height={props.height} width={props.width} colSpan={props.colSpan} rowSpan={props.rowSpan} align={props.align || 'center'}>{props.children}</MuiTableCell>;
};

export default TableHeaderCell;