// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '../../common/table-cell';
// import TableRow from "../../common/table-row";
import TokenPnlWidgetStyled from "./token-pnl-widget.styled.ts";
import FlexContainer from "../../common/flex-container";
import WidgetData from "../types/widget-data.ts";
import Tooltip from "../../common/tooltip";
import formatNumber from "../../common/format-number";
import styles from './styles.module.css';
import {Typography} from "@mui/material";
import OperationIndication from "../../common/operation-indication";
import PhraseContainer from "../../common/phrase-container";

type TokenPnlWidgetProps = {
  widgetData: Array<WidgetData> | null,
}

const TokenPnlWidget: React.FunctionComponent<TokenPnlWidgetProps> = (props: TokenPnlWidgetProps) => {
  return <FlexContainer alignItems="center" flexDirection="column" marginTop={10}>
    {props.widgetData?.map((widgetDataElement: WidgetData) => {

      const dollarsForOneTokenRounded: string | null = formatNumber(widgetDataElement.dollarsForOneToken, 4);
      const tokensActualRounded: string | null = formatNumber(widgetDataElement.tokensActual, 2);
      const tokensBoughtRounded: string | null = formatNumber(widgetDataElement.tokensBought, 2);
      const tokensReceiveRounded: string | null = formatNumber(widgetDataElement.tokensReceived, 2);
      const tokensSentRounded: string | null = formatNumber(widgetDataElement.tokensSent, 2);
      const tokensSoldRounded: string | null = formatNumber(widgetDataElement.tokensSold, 2);
      const resultInTonsRounded: string | null = formatNumber(widgetDataElement.resultInTons, 2);

      return (<TokenPnlWidgetStyled key={widgetDataElement.id}>
        <FlexContainer flexDirection="column" alignItems="flex-start" borderRadius="10px" outline="1px solid var(--font-color-opacity-05)" padding="10px">
          <Typography variant="h3" className={styles['widget-title']}>
            Account: {widgetDataElement.account}
          </Typography>
          <FlexContainer gap={10} margin="10px 0 0 0">
            <FlexContainer flexDirection="column" width={300} borderRadius="10px" justifyContent="flex-start"  outline="1px solid var(--font-color-opacity-05)" padding="10px" gap={10}>
              <FlexContainer justifyContent="space-between">
                <PhraseContainer fontSize={16} alignItems="center" color="var(--primary-main-color)">Balance</PhraseContainer>
                <Tooltip title={String(widgetDataElement.tokensActual)}><PhraseContainer fontSize={16} width="initial" justifyContent="flex-end" alignItems="center" color="var(--primary-main-color)">{tokensActualRounded}</PhraseContainer></Tooltip>
                <PhraseContainer width="initial" fontSize={16} alignItems="center"  color="var(--primary-main-color)"> {widgetDataElement.reportToken} </PhraseContainer>
              </FlexContainer>
              <FlexContainer justifyContent="space-between" alignItems="center">
                <PhraseContainer fontSize={16}>Realized PNL</PhraseContainer>
                <Tooltip title={String(widgetDataElement.resultInTons)}>
                  <OperationIndication isSendingOperation={Number(widgetDataElement.resultInTons) < 0} fontSize={16}>{resultInTonsRounded}</OperationIndication>
                </Tooltip>
              </FlexContainer>
              <FlexContainer justifyContent="space-between" alignItems="center">
                <PhraseContainer fontSize={16}>Unrealized PNL</PhraseContainer>
                <OperationIndication isSendingOperation={Number(widgetDataElement.unrealizedPnl) < 0} fontSize={16}>{widgetDataElement.unrealizedPnl}</OperationIndication>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width={250} borderRadius="10px" outline="1px solid var(--font-color-opacity-05)" padding="10px" gap={10}>
              <FlexContainer flexDirection="column" justifyContent="space-between" width="100%">
                <FlexContainer justifyContent="space-between">
                  <FlexContainer justifyContent="flex-start"  gap={10}>
                    <PhraseContainer width="initial" fontSize={16} color="var(--recieving-operation-color)" alignItems="center">BUYS</PhraseContainer>
                    <PhraseContainer width="initial" fontSize={16} color="var(--recieving-operation-color)" alignItems="center">{widgetDataElement.buys}</PhraseContainer>
                  </FlexContainer>
                  <FlexContainer justifyContent="flex-start"  gap={10}>
                    <PhraseContainer width="initial" fontSize={16} color="var(--recieving-operation-color)" alignItems="center">Received</PhraseContainer>
                    <PhraseContainer width="initial" fontSize={16} color="var(--recieving-operation-color)" alignItems="center">{widgetDataElement.received}</PhraseContainer>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" alignItems="center">
                  <PhraseContainer width="initial" fontSize={16} color="var(--recieving-operation-color)">
                    Bought
                  </PhraseContainer>
                  <Tooltip title={String(widgetDataElement.tokensBought)}>
                    <OperationIndication isSendingOperation={false} fontSize={16} fontWeight={900}>
                      {tokensBoughtRounded}
                    </OperationIndication>
                  </Tooltip>
                  <PhraseContainer width="initial" fontSize={16} alignItems="center" color="var(--recieving-operation-color)"> {widgetDataElement.reportToken} </PhraseContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" alignItems="center">
                  <PhraseContainer width="initial" fontSize={16} color="var(--recieving-operation-color)">
                    Received
                  </PhraseContainer>
                  <Tooltip title={String(widgetDataElement.tokensReceived)}>
                    <OperationIndication isSendingOperation={false} fontSize={16} fontWeight={900}>
                      {tokensReceiveRounded}
                    </OperationIndication>
                  </Tooltip>
                  <PhraseContainer width="initial" fontSize={16} alignItems="center" color="var(--recieving-operation-color)"> {widgetDataElement.reportToken} </PhraseContainer>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width={250} borderRadius="10px" outline="1px solid var(--font-color-opacity-05)" padding="10px" gap={10}>
              <FlexContainer flexDirection="column" justifyContent="space-between" width="100%">
                <FlexContainer justifyContent="space-between">
                <FlexContainer justifyContent="flex-start" gap={10}>
                  <PhraseContainer width="initial" fontSize={16} color="var(--sending-operation-color)" alignItems="center">Sells</PhraseContainer>
                  <PhraseContainer width="initial" fontSize={16} color="var(--sending-operation-color)" alignItems="center">{widgetDataElement.sells}</PhraseContainer>
                </FlexContainer>
                  <FlexContainer justifyContent="flex-start" gap={10}>
                    <PhraseContainer width="initial" fontSize={16} color="var(--sending-operation-color)" alignItems="center">Sent</PhraseContainer>
                    <PhraseContainer width="initial" fontSize={16} color="var(--sending-operation-color)" alignItems="center">{widgetDataElement.sent}</PhraseContainer>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" alignItems="center">
                  <PhraseContainer width="initial" fontSize={16} color="var(--sending-operation-color)">
                    Sold
                  </PhraseContainer>
                  <Tooltip title={String(widgetDataElement.tokensSold)}>
                    <OperationIndication isSendingOperation={true} fontSize={16} fontWeight={900}>
                      {tokensSoldRounded}
                    </OperationIndication>
                  </Tooltip>
                  <PhraseContainer width="initial" fontSize={16} alignItems="center" color="var(--sending-operation-color)"> {widgetDataElement.reportToken} </PhraseContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" alignItems="center">
                  <PhraseContainer width="initial" fontSize={16} color="var(--sending-operation-color)">
                    Sent
                  </PhraseContainer>
                  <Tooltip title={String(widgetDataElement.tokensSent)}>
                    <OperationIndication isSendingOperation={true} fontSize={16} fontWeight={900}>
                      {tokensSentRounded}
                    </OperationIndication>
                  </Tooltip>
                  <PhraseContainer width="initial" fontSize={16} alignItems="center" color="var(--sending-operation-color)"> {widgetDataElement.reportToken} </PhraseContainer>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column" width={200} borderRadius="10px" justifyContent="flex-start"  outline="1px solid var(--font-color-opacity-05)" padding="10px" gap={10}>
              <FlexContainer justifyContent="space-between">
                <PhraseContainer fontSize={16} textAlign="left">Dollars for one token</PhraseContainer>
                <Tooltip title={String(widgetDataElement.dollarsForOneToken)}>
                  <PhraseContainer fontSize={16}>
                    {dollarsForOneTokenRounded}
                  </PhraseContainer>
                </Tooltip>
              </FlexContainer>
              <FlexContainer justifyContent="space-between">
                <PhraseContainer fontSize={16}>Inner transfer</PhraseContainer>
                <PhraseContainer fontSize={16}>{widgetDataElement.innerTransfers}</PhraseContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          {/*<Table className={styles['table-font-size']}>*/}
          {/*  <TableBody>*/}
          {/*    <TableRow>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*      <FlexContainer color="var(--primary-main-color)">*/}
          {/*          Report token*/}
          {/*        </FlexContainer>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <FlexContainer color="var(--primary-main-color)">*/}
          {/*          {widgetDataElement.reportToken}*/}
          {/*        </FlexContainer>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <FlexContainer color="var(--primary-main-color)">*/}
          {/*          Transactions*/}
          {/*        </FlexContainer>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <FlexContainer color="var(--primary-main-color)" justifyContent="center">*/}
          {/*          {widgetDataElement.transactions}*/}
          {/*        </FlexContainer>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={false} fontSize={16} fontWeight={900}>*/}
          {/*          Buys*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <OperationIndication isSendingOperation={false} fontSize={16}>*/}
          {/*          {widgetDataElement.buys}*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16} fontWeight={900}>Sent</OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16}>*/}
          {/*          {widgetDataElement.sent}*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16} fontWeight={900}>*/}
          {/*          Sells*/}
          {/*        </OperationIndication>*/}
          {/*        </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16}>*/}
          {/*          {widgetDataElement.sells}*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>Inner transfer</TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>{widgetDataElement.innerTransfers}</TableCell>*/}
          {/*    </TableRow>*/}
          {/*    <TableRow>*/}
          {/*      <TableCell className={styles['table-cell-title']}>Dollars for one token</TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}><Tooltip title={String(widgetDataElement.dollarsForOneToken)}>{dollarsForOneTokenRounded}</Tooltip></TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>Tokens actual</TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}><Tooltip title={String(widgetDataElement.tokensActual)}>{tokensActualRounded}</Tooltip></TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={false} fontSize={16} fontWeight={900}>*/}
          {/*          Tokens bought*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <Tooltip title={String(widgetDataElement.tokensBought)}>*/}
          {/*          <OperationIndication isSendingOperation={false} fontSize={16} fontWeight={900}>*/}
          {/*            {tokensBoughtRounded}*/}
          {/*          </OperationIndication>*/}
          {/*        </Tooltip>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={false} fontSize={16} fontWeight={900}>*/}
          {/*          Tokens received*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <Tooltip title={String(widgetDataElement.tokensReceived)}>*/}
          {/*          <OperationIndication isSendingOperation={false} fontSize={16}>*/}
          {/*          {tokensReceiveRounded}*/}
          {/*          </OperationIndication>*/}
          {/*        </Tooltip>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16} fontWeight={900}>*/}
          {/*          Tokens sent*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}>*/}
          {/*        <Tooltip title={String(widgetDataElement.tokensSent)}>*/}
          {/*          <OperationIndication isSendingOperation={true} fontSize={16}>*/}
          {/*            {tokensSentRounded}*/}
          {/*          </OperationIndication>*/}
          {/*        </Tooltip>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-title']}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16} fontWeight={900}>*/}
          {/*          Tokens sold*/}
          {/*        </OperationIndication>*/}
          {/*      </TableCell>*/}
          {/*      <TableCell className={styles['table-cell-value']}><Tooltip title={String(widgetDataElement.tokensSold)}>*/}
          {/*        <OperationIndication isSendingOperation={true} fontSize={16}>*/}
          {/*          {tokensSoldRounded}*/}
          {/*        </OperationIndication>*/}
          {/*        </Tooltip>*/}
          {/*      </TableCell>*/}
          {/*    </TableRow>*/}
          {/*  </TableBody>*/}
          {/*</Table>*/}
        </FlexContainer>
      </TokenPnlWidgetStyled>);
    }) ?? null}
  </FlexContainer>;
};

export default TokenPnlWidget;