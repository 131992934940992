import RootContainerStyled from "./root-container.styled.ts";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type AppProps = {
  children: React.ReactNode;
}


const App: React.FunctionComponent<AppProps> = (props: AppProps) => {
  return (
    <RootContainerStyled>
      {props.children}
      <ToastContainer theme="dark" draggable />
    </RootContainerStyled>
  );
};

export default App;
