import MuiTableRow from "@mui/material/TableRow";
import styles from './index.module.css';

type TableRowProps = {
  children: React.ReactNode,
  className?: string,
}

const TableRow: React.FunctionComponent<TableRowProps> = (props: TableRowProps) => {
  return <MuiTableRow className={styles['table-row']}>{props.children}</MuiTableRow>;
};

export default TableRow;