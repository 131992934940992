import orderBy from 'lodash.orderby';
import SortDirection from '../../../enums/sort-direction.ts';
import type DynamicMenuItem from '../types/dynamic-menu-item.ts';

function dynamicMenuMapped(dynamicMenuData: any): DynamicMenuItem[] {
  if (!dynamicMenuData?.length) {
    return [];
  }
  const dynamicMenuDataParsed: DynamicMenuItem[] = dynamicMenuData?.map((menuItem: any) => ({
    name: menuItem?.name ?? null,
    link: menuItem?.link ?? null,
    order: menuItem?.order ?? null,
    parent: menuItem?.parent ?? null,
    image: menuItem?.image ?? null,
  }));
  const dynamicMenuDataParsedParents: DynamicMenuItem[] = dynamicMenuDataParsed.filter(
    (dynamicMenuDataElement: DynamicMenuItem) => !dynamicMenuDataElement.parent,
  );
  const dynamicMenuDataParsedParentsSorted: DynamicMenuItem[] = orderBy(
    dynamicMenuDataParsedParents,
    'order',
    SortDirection.Asc,
  );

  return dynamicMenuDataParsedParentsSorted.map((dynamicMenuDataElement: DynamicMenuItem) => {
    const children: DynamicMenuItem[] = dynamicMenuDataParsed.filter(
      (dynamicMenuDataElementChild: DynamicMenuItem) =>
        dynamicMenuDataElementChild.parent === dynamicMenuDataElement.name,
    );

    const dynamicMenuDataElementChildrenSorted: DynamicMenuItem[] = orderBy(
      children,
      'order',
      SortDirection.Asc,
    );
    dynamicMenuDataElement.children = dynamicMenuDataElementChildrenSorted;
    return dynamicMenuDataElement;
  });
}

export default dynamicMenuMapped;
