import MuiTableCell from '@mui/material/TableCell';
import {CSSProperties} from "react";

type TableCellProps = {
  children: React.ReactNode,
  className?: string | undefined,
  style?: CSSProperties | undefined,
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined,
}

const TableCell: React.FunctionComponent<TableCellProps> = (props: TableCellProps) => {
  return <MuiTableCell {...props} align={props.align || 'center'}>{props.children}</MuiTableCell>;
};

export default TableCell;