import { useQuery } from '@tanstack/react-query';
import { Outlet, RouterProvider, createRootRoute, createRoute, createRouter } from '@tanstack/react-router';
import { useTonWallet } from '@tonconnect/ui-react';
import { useEffect, useState } from 'react';
import App from './components/app';
// import {TanStackRouterDevtools} from "@tanstack/router-devtools";
import MainLayout from './components/main-layout';
import Test from './components/test';
import { getTokensByWallet } from './components/tokens/api';
import tokensMetadataMapping from './components/tokens/mapping/tokens-metadata-mapping.ts';
import type TokenMetadataItem from './components/tokens/types/token-metadata-item.ts';
import RouteName from './enums/route-name.ts';
import NftReward from './pages/nft-reward';
import Raffle from './pages/raffle';
import Swap from './pages/swap';
import TokenPnl from './pages/token-pnl';

const Routes: React.FunctionComponent = () => {
  const [accountAddress, setAccountAddress] = useState<string | null>(null);
  const walletData: any = useTonWallet();

  useEffect(() => {
    if (walletData) {
      // console.log(walletData);
      setAccountAddress(walletData.account.address);
    }
  }, [walletData]);

  // const tokensMetadataResult = useQuery({
  //   queryKey: ['tokens'],
  //   queryFn: getTokensMetadata,
  //   enabled: false,
  // });

  const tokensByWalletResult = useQuery({
    queryKey: ['tokens-by-wallet'],
    queryFn: () => getTokensByWallet(accountAddress as string),
    enabled: false,
  });

  useEffect(() => {
    if (accountAddress) {
      tokensByWalletResult.refetch();
    }
  }, [accountAddress]);

  // const { data: tokensMetadataResultData }: { data: any } = tokensMetadataResult;
  const { data: tokensByWalletData }: { data: any } = tokensByWalletResult;

  const { tokensMetadata, tokensSymbols }: { tokensMetadata: TokenMetadataItem[]; tokensSymbols: string[] } =
    tokensByWalletData?.data
      ? tokensMetadataMapping(tokensByWalletData?.data?.data)
      : { tokensMetadata: [], tokensSymbols: [] };

  const rootRoute = createRootRoute({
    component: () => (
      <App>
        <MainLayout>
          <Outlet />
        </MainLayout>
        {/*<TanStackRouterDevtools />*/}
      </App>
    ),
  });

  const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: function Index() {
      return <NftReward tokensMetadata={tokensMetadata} tokensSymbols={tokensSymbols} />;
    },
  });

  const swapRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/${RouteName.Swap}`,
    component: function Index() {
      return <Swap />;
    },
  });

  const nftRewardRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/${RouteName.NftReward}`,
    component: function Index() {
      return <NftReward tokensMetadata={tokensMetadata} tokensSymbols={tokensSymbols} />;
    },
  });

  const nftRewardWithCollectionAddressRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/${RouteName.NftReward}/$collectionAddress`,
    component: function Index() {
      return <NftReward tokensMetadata={tokensMetadata} tokensSymbols={tokensSymbols} />;
    },
  });

  const tokenPnlRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/${RouteName.TokenPnl}`,
    component: function Index() {
      return <TokenPnl tokensMetadata={tokensMetadata} />;
    },
  });

  const raffleRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/${RouteName.Raffle}`,
    component: function Index() {
      return <Raffle />;
    },
  });

  const testRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/${RouteName.Test}`,
    component: function Index() {
      return <Test />;
    },
  });

  const routeTree = rootRoute.addChildren([
    indexRoute,
    tokenPnlRoute,
    raffleRoute,
    nftRewardRoute,
    nftRewardWithCollectionAddressRoute,
    swapRoute,
    testRoute,
  ]);

  const router = createRouter({ routeTree });

  return <RouterProvider router={router} />;
};

export default Routes;
