import styled from "styled-components";

const MainContainerSectionStyled = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100dvh - 10px);
  max-width: 1920px;
`;

export default MainContainerSectionStyled;