import { useEffect, useState } from 'react';
import FlexContainer from '../common/flex-container';
import LabelStyled from '../nft-reward/nft-reward-form/label.styled.ts';
import styled from './styles.module.css';

type NameSwitcherProps = {
  id: string;
  name: string;
  value: string;
  labelActive: string;
  labelInactive: string;
  labelInactive2?: string;
  onChange: (name: string, value: string) => void;
  disabled?: boolean;
  className?: string;
};

const NameSwitcher: React.FunctionComponent<NameSwitcherProps> = (props: NameSwitcherProps) => {
  const [labelActive, setLabelActive] = useState<string>(props.value);

  useEffect(() => {
    setLabelActive(props.value);
  }, [props.labelActive, props.value]);

  const onRadioChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target as HTMLInputElement;
    const value: string = element.dataset?.value || '';
    props.onChange(element.name, value);
  };

  const isFirstElementActive = labelActive === props.labelActive;
  const isSecondElementActive = labelActive === props.labelInactive;
  const isThirdElementActive = labelActive === props?.labelInactive2;

  return (
    <FlexContainer alignItems="center" fontSize={14}>
      <LabelStyled
        fontSize={14}
        color={
          props.disabled
            ? 'var(--disabled-color)'
            : isFirstElementActive
              ? 'var(--primary-main-color)'
              : 'var(--main-font-color)'
        }
        htmlFor={`label-active-${props.id}`}
        disabled={props.disabled}
      >
        {props.labelActive}
      </LabelStyled>
      <input
        id={`label-active-${props.id}`}
        type="radio"
        name={props.name}
        data-value={props.labelActive}
        checked={isFirstElementActive}
        value="label-active"
        onChange={onRadioChange}
        className={styled['radio-button']}
        disabled={props.disabled}
      />
      /
      <LabelStyled
        fontSize={14}
        color={
          props.disabled
            ? 'var(--disabled-color)'
            : isSecondElementActive
              ? 'var(--primary-main-color)'
              : 'var(--main-font-color)'
        }
        htmlFor={`label-inactive-${props.id}`}
        disabled={props.disabled}
      >
        {props.labelInactive}
      </LabelStyled>
      <input
        id={`label-inactive-${props.id}`}
        type="radio"
        name={props.name}
        data-value={props.labelInactive}
        checked={isSecondElementActive}
        value="label-inactive"
        onChange={onRadioChange}
        className={styled['radio-button']}
        disabled={props.disabled}
      />
      {props.labelInactive2 && (
        <>
          /
          <LabelStyled
            fontSize={14}
            color={
              props.disabled
                ? 'var(--disabled-color)'
                : isThirdElementActive
                  ? 'var(--primary-main-color)'
                  : 'var(--main-font-color)'
            }
            htmlFor={`label-inactive-2-${props.id}`}
            disabled={props.disabled}
          >
            {props.labelInactive2}
          </LabelStyled>
          <input
            id={`label-inactive-2-${props.id}`}
            type="radio"
            name={props.name}
            data-value={props.labelInactive2}
            checked={isThirdElementActive}
            value="label-inactive-2"
            onChange={onRadioChange}
            className={styled['radio-button']}
            disabled={props.disabled}
          />
        </>
      )}
    </FlexContainer>
  );
};

export default NameSwitcher;
