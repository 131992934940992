import { FormControl, FormLabel } from '@mui/material';
import { useTonAddress } from '@tonconnect/ui-react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import getFromLocalStorage from '../../../helpers/local-storage/get-from-local-storage.ts';
import setToLocalStorage from '../../../helpers/local-storage/set-to-local-storage.ts';
import type Inputs from '../../../pages/token-pnl/types/inputs.ts';
import Button from '../../common/button';
import DatePicker from '../../common/date-picker';
import FlexContainer from '../../common/flex-container';
import OutlinedInput from '../../common/outlined-input';
import TextField from '../../common/textfield';
import SelectTokens from '../../tokens/select-tokens';
import type TokenMetadataItem from '../../tokens/types/token-metadata-item.ts';
import FormInputsContainerStyled from './form-inputs-container.styled.ts';
import accountsIdsParse from './helpers/accounts-ids-parse.ts';
import styles from './index.module.css';

type TokenPnlFormProps = {
  onTransactionsSearch: (params: Inputs) => void;
  tokensMetadata: TokenMetadataItem[];
};

const TokenPnlForm: React.FunctionComponent<TokenPnlFormProps> = (props: TokenPnlFormProps) => {
  const userFriendlyWalletAddress: string = useTonAddress();
  const dateFromLocaleStorage: string = getFromLocalStorage('dataFrom');
  const dateToLocaleStorage: string = getFromLocalStorage('dataTo');

  const [token, setToken] = useState<string | undefined>(getFromLocalStorage('token') || 'TON');
  const [accountsIds, setAccountsIds] = useState<string>(getFromLocalStorage('accountsIds') || '');
  const [dateFrom, setDateFrom] = useState<Dayjs | null | undefined>(
    dateFromLocaleStorage ? dayjs(dateFromLocaleStorage, 'YYYY-MM-DD') : undefined,
  );
  const [dateTo, setDateTo] = useState<Dayjs | null | undefined>(
    dateToLocaleStorage ? dayjs(dateToLocaleStorage, 'YYYY-MM-DD') : undefined,
  );

  useEffect(() => {
    if (userFriendlyWalletAddress && !accountsIds) {
      setAccountsIds(userFriendlyWalletAddress);
    }
  }, [userFriendlyWalletAddress]);

  const onTokenChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target;
    setToken(element.value);
    setToLocalStorage('token', element.value);
  };

  const onCollectioAddressSelectChange = (option: any) => {
    setToken(option?.value?.symbol);
    setToLocalStorage('token', option?.value?.symbol);
  };

  const onDateFromChange = (value: Dayjs | null) => {
    setDateFrom(value);
    setToLocalStorage('dataFrom', value ? value.format('YYYY-MM-DD') : '');
  };

  const onDateToChange = (value: Dayjs | null) => {
    setDateTo(value);
    setToLocalStorage('dataTo', value ? value.format('YYYY-MM-DD') : '');
  };

  const onAccountsIdsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const element = evt.target;
    const elementValue: string = element?.value ? element.value : '';
    const accountsIds: string = accountsIdsParse(elementValue)?.join('\n');
    setAccountsIds(accountsIds);
    setToLocalStorage('accountsIds', accountsIds);
  };

  const onTransactionsSearch = () => {
    props.onTransactionsSearch({
      token,
      accountsIds,
      dateFrom: dateFromParsed ? dateFromParsed.format('YYYY-MM-DD') : '',
      dateTo: dateToParsed ? dateToParsed.format('YYYY-MM-DD') : '',
    });
  };

  const dateFromParsed: Dayjs | undefined = dateFrom ? dayjs(dateFrom) : undefined;
  const dateToParsed: Dayjs | undefined = dateTo ? dayjs(dateTo) : undefined;

  return (
    <form className={styles.form}>
      <FlexContainer flexDirection="column">
        <FlexContainer alignItems="center" gap={10}>
          <FormControl className={styles['form-token-container']}>
            <FormLabel htmlFor="token" className={styles.label}>
              Token *
            </FormLabel>
            {props.tokensMetadata.length > 0 ? (
              <SelectTokens
                id="distribute-token"
                name="distributeToken"
                activeElement={token}
                tokensMetadata={props.tokensMetadata}
                onSelectChange={onCollectioAddressSelectChange}
              />
            ) : (
              <TextField
                id="token"
                name="token"
                variant="outlined"
                onChange={onTokenChange}
                value={token}
                isError={!token}
              />
            )}
          </FormControl>
          <FormControl className={styles['form-date-container']}>
            <FormLabel className={styles['datepicker-label']}>Date from *</FormLabel>
            <DatePicker
              name="dateFrom"
              value={dateFromParsed}
              onChange={onDateFromChange}
              isError={true}
              maxDate={dateToParsed}
            />
          </FormControl>
          <FormControl className={styles['form-date-container']}>
            <FormLabel className={styles['datepicker-label']}>Date to *</FormLabel>
            <DatePicker
              name="dateTo"
              value={dateToParsed}
              onChange={onDateToChange}
              isError={!dateFromParsed && !dateToParsed}
              minDate={dateFromParsed}
            />
          </FormControl>
        </FlexContainer>
        <FormInputsContainerStyled>
          <FormLabel className={styles.label}>Account Id*</FormLabel>
          <OutlinedInput
            name="accountsIds"
            fullWidth={true}
            variant="outlined"
            maxRows={5}
            value={accountsIds}
            multiline={true}
            onChange={onAccountsIdsChange}
            className={styles['form-account-ids-input']}
          />
        </FormInputsContainerStyled>
      </FlexContainer>
      <FlexContainer justifyContent="center">
        <Button
          onClick={onTransactionsSearch}
          type="button"
          variant="outlined"
          disabled={(!dateFrom && !dateTo) || !accountsIds}
        >
          Search
        </Button>
      </FlexContainer>
    </form>
  );
};

export default TokenPnlForm;
