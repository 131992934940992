import { Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { TonConnectButton } from '@tonconnect/ui-react';
import { useEffect } from 'react';
import { DEVELOPMENT_MODE, STATIC_IMG_LOCATION } from '../../constants';
import FlexContainer from '../common/flex-container';
import IconButton from '../common/icon-button';
import styles from './index.module.css';
import MainTitleContainerStyled from './main-title-container.styled.ts';

type MainHeaderProps = {
  isVisibleMainMenu: boolean;
  isMobileResolution: boolean | undefined;
  onMainMenuVisibleChange: () => void;
};

const MainHeader: React.FunctionComponent<MainHeaderProps> = (props: MainHeaderProps) => {
  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV_MODE === DEVELOPMENT_MODE) {
      document.title = import.meta.env.VITE_APP_TITLE;
    }
  }, []);

  return (
    <MainTitleContainerStyled>
      <FlexContainer justifyContent="space-between" alignItems="center" gap={5} width="calc(100% - 10px)">
        <Link to="/" className={styles.link}>
          <FlexContainer justifyContent="flex-start" alignItems="center" gap={5} width="initial">
            <img width="36" height="36" src={`${STATIC_IMG_LOCATION}/mybit-money.png`} alt="mybit-money" />
            <Typography variant="h1" className={styles['header-h1']}>
              My₿iT.money (beta)
            </Typography>
          </FlexContainer>
        </Link>
        <TonConnectButton className={styles['ton-connect-button']} />
        {props.isMobileResolution && (
          <IconButton onClick={props.onMainMenuVisibleChange}>
            {props.isVisibleMainMenu ? <Close /> : <MenuIcon />}
          </IconButton>
        )}
      </FlexContainer>
    </MainTitleContainerStyled>
  );
};

export default MainHeader;
