import styled from 'styled-components';

const RootContainerStyled = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: start;
  font-size: var(--main-font-size);
  font-family: var(--primary-font-family);
  margin: 5px 20px;
  width: calc(100% - 40px);
  height: calc(100dvh - 10px);
  @media (max-width: 767px) {
    overflow-x: auto;
    margin: 5px;
    width: calc(100% - 10px);
  }
`;

export default RootContainerStyled;
