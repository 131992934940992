export const DEVELOPMENT_MODE = 'development';
export const STATIC_IMG_LOCATION =
  import.meta.env.VITE_NODE_ENV_MODE === 'development'
    ? 'https://static.mybit.me/img'
    : `${document.location.protocol}//static.${document.location.host}/img`;

export const STATIC_FONTS_LOCATION =
  import.meta.env.VITE_NODE_ENV_MODE === 'development'
    ? 'https://static.mybit.me/fonts'
    : `${document.location.protocol}//static.${document.location.host}/fonts`;

export const API_LOCATION =
  import.meta.env.VITE_NODE_ENV_MODE === 'development'
    ? 'https://api.mybit.me'
    : `${document.location.protocol}//api.${document.location.host}`;

export const DEFAULT_COMPONENT_WIDTH = '220px';
export const DEFAULT_COMPONENT_HEIGHT = '40px';
export const DEFAULT_INPUT_STYLED = 'outlined';
export const DEFAULT_INPUT_DESKTOP_PADDING = '2px 5px';
export const DEFAULT_INPUT_MOBILE_PADDING = '2px 16px';
