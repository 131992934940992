import type Inputs from '../../pages/token-pnl/types/inputs.ts';
import type TokenMetadataItem from '../tokens/types/token-metadata-item.ts';
import styles from './index.module.css';
import TokenPnlForm from './token-pnl-form';
import TokenPnlWidget from './token-pnl-widget';
import TransactionsDetails from './transactions-details';
import type TransactionItem from './types/transaction-item.ts';
import type WidgetData from './types/widget-data.ts';

type TransactionsProps = {
  transactions: Array<TransactionItem> | null;
  widgetData: Array<WidgetData> | null;
  isLoadingTransactions: boolean;
  onTransactionsSearch: (params: Inputs) => void;
  tokensMetadata: TokenMetadataItem[];
};

const TokenPnl: React.FunctionComponent<TransactionsProps> = (props: TransactionsProps) => {
  return (
    <div className={styles['transactions-container']}>
      <TokenPnlForm tokensMetadata={props.tokensMetadata} onTransactionsSearch={props.onTransactionsSearch} />
      <TokenPnlWidget widgetData={props.widgetData} />
      <TransactionsDetails
        transactions={props.transactions}
        isLoadingTransactions={props.isLoadingTransactions}
      />
    </div>
  );
};

export default TokenPnl;
