import { axiosInstance } from '../../../api/client-base.ts';
import InstanceType from '../../../enums/instance-type.ts';

// const dynamicMenu = [
//   {
//     parent: null,
//     name: 'MyBitMoney',
//     link: 'https://mybit.money',
//     order: 0,
//   },
//   {
//     parent: null,
//     name: 'Portfolio',
//     order: 1,
//   },
//   {
//     parent: 'Portfolio',
//     name: 'Operations (soon)',
//   },
//   {
//     parent: null,
//     name: 'Trade',
//     order: 2,
//   },
//   {
//     parent: 'Trade',
//     name: 'Swap (soon)',
//     link: '/swap',
//   },
//   {
//     parent: null,
//     name: 'TonTools',
//     order: 3,
//   },
//   {
//     parent: 'TonTools',
//     name: 'NFT Reward',
//     link: '/nft-reward',
//   },
//   {
//     parent: 'TonTools',
//     name: 'Token P&L (beta)',
//     link: '/token-pnl',
//   },
//   {
//     parent: null,
//     name: 'Docs',
//     order: 5,
//   },
//   {
//     parent: 'Docs',
//     name: 'Readme',
//     link: 'https://github.com/mybit-money/docs',
//   },
//   {
//     parent: 'Docs',
//     name: 'Whats new',
//     link: 'https://github.com/mybit-money/docs/blob/main/CHANGELOG.md',
//   },
//   {
//     parent: null,
//     name: 'DeFi',
//     order: 1,
//   },
//   {
//     parent: 'DeFi',
//     name: 'Storm Trade',
//     link: 'https://app.storm.tg/?ref=4A9K2AU9KP',
//     tags: ['Derivatives', 'Earn', 'Staking', 'RevShare'],
//   },
//   {
//     parent: null,
//     name: 'CEX',
//     order: 2,
//   },
//   {
//     parent: 'CEX',
//     name: 'Bybit',
//     link: 'https://partner.bybit.com/b/mybitmoney',
//   },
// ];

export const fetchDataDynamicMenu = async () => {
  // return Promise.resolve(dynamicMenu);
  return await axiosInstance({
    method: 'GET',
    url: `${InstanceType.Api}/catalog/GetCatalog?ViewSize=Large&ForAddress=mybit-money.ton`,
  });
};
