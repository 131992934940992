import MuiTextField from '@mui/material/TextField';
import type CSS from 'csstype';
import {
  DEFAULT_COMPONENT_HEIGHT,
  DEFAULT_INPUT_DESKTOP_PADDING,
  DEFAULT_INPUT_MOBILE_PADDING,
  DEFAULT_INPUT_STYLED,
} from '../../../constants';

type MuiTextFieldProps = {
  value: string | number | undefined;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  variant?: unknown;
  isRequired?: boolean;
  isError?: boolean;
  type?: string;
  label?: string;
  disabled?: boolean;
  height?: string;
  textAlign?: CSS.Property.TextAlign;
  fontSize?: CSS.Property.FontSize;
};

const TextField: React.FunctionComponent<MuiTextFieldProps> = (props: any) => {
  return (
    <MuiTextField
      name={props.name}
      id={props?.id}
      error={props?.isError || (props.isRequired && !props?.value)}
      color="primary"
      variant={props?.variant ?? DEFAULT_INPUT_STYLED}
      value={props.value}
      onChange={props.onChange}
      fullWidth={true}
      type={props?.type}
      label={props?.label}
      disabled={props.disabled}
      sx={{
        '& input': {
          boxSizing: 'border-box',
          padding: DEFAULT_INPUT_DESKTOP_PADDING,
          height: props?.height ?? DEFAULT_COMPONENT_HEIGHT,
          textAlign: props?.textAlign ?? 'initial',
          fontSize: `${props?.fontSize ?? 'var(--main-font-size)'} !important`,
        },
        '& label': {
          transform: 'translate(14px, 12px) scale(1) !important',
        },
        '& label.MuiFormLabel-filled': {
          transform: 'translate(20px, -5px) scale(0.75) !important',
        },
        '& label.Mui-focused': {
          transform: 'translate(20px, -5px) scale(0.75) !important',
        },
        '@media (max-width: 768px)': {
          '& input': {
            padding: DEFAULT_INPUT_MOBILE_PADDING,
          },
          '& fieldset': {
            borderRadius: '21px',
            borderColor: `${props?.isError ? 'var(--secondary-main-color)' : 'var(--primary-main-color)'} !important`,
          },
        },
      }}
    />
  );
};

export default TextField;
