import NtfRewardItem from "../types/ntf-reward-item.ts";
import round from 'lodash.round';


function ntfRewardReportMapped(nftReportData: any[]): NtfRewardItem[] {
  return nftReportData.map((nftReportElement: any) => ({
    nftOwnerAddress: nftReportElement?.nft_owner_address ?? null,
    count: nftReportElement?.nft_count ?? null,
    amount: nftReportElement?.distribute_amount !== null && nftReportElement?.distribute_amount !== undefined ? round(nftReportElement?.distribute_amount, 2) : null,
    token: nftReportElement?.distribute_token ?? null,
    comment: nftReportElement?.distribute_message ?? null,
  }));
}

export default ntfRewardReportMapped;