import styled from "styled-components";

const MainLayoutContainerStyled = styled.div`
  position: relative;
  display: grid;
  gap: 20px;
  grid-template-columns: 200px auto;
  @media(max-width: 767px) {
    grid-template-columns: 1fr;
    width: 100%;
    overflow-x: auto;
    flex-grow: 1;
  }
`;

export default MainLayoutContainerStyled;