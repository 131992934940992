import isNotEmptyNumber from './is-not-empty-number';
import isNotEmptyString from './is-not-empty-string';

function getMeasureValue(value: number | string | undefined, defaultValue: string = 'initial'): string {
  return typeof value === 'string'
    ? isNotEmptyString(value)
      ? value
      : defaultValue
    : typeof value === 'number' && isNotEmptyNumber(value)
      ? `${value}px`
      : defaultValue;
}

export default getMeasureValue;