function parseBlobFile(blobFile: Blob, disposition: any, fileType: string): { pathNameToFile, fileName } {
  let fileName: string = '';
  const fileNameRegex = /filename[^;=\n]*=((['"]).*\2[[^;\n]*)/;
  const matches: RegExpExecArray | null = fileNameRegex.exec(disposition);
  if (matches !== null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, '');
  }
  const blob = new Blob([blobFile], { type: fileType});
  const pathNameToFile = window.URL.createObjectURL(blob);
  return { pathNameToFile, fileName };
}

export default parseBlobFile;