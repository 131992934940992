import styled from 'styled-components';

const SidebarItemHeaderStyled = styled.p`
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--primary-font-family);
  font-weight: 900;
  font-size: var(--main-font-size);
  margin: 10px 0 0 0;
  text-align: left;
  justify-content: flex-start;
  @media (max-width: 767px) {
    font-size: calc(var(--main-font-size) + 10px);
    justify-content: center;
     &:before {
       content: '';
       display: block;
       border-bottom: 1px solid var(--main-font-color);
       height: 3px;
       width: 100%;
       position: absolute;
       top: -8px;
     }
  }
`;

export default SidebarItemHeaderStyled;
