import { LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import getFromLocalStorage from '../../helpers/local-storage/get-from-local-storage.ts';
import setToLocalStorage from '../../helpers/local-storage/set-to-local-storage.ts';
import FlexContainer from '../common/flex-container';
import type TokenMetadataItem from '../tokens/types/token-metadata-item.ts';
import { getNftRewardReport } from './api';
import OwnerAddressFormat from './enums/owner-address-format.ts';
import ntfRewardReportMapped from './mapping/ntf-reward-report-mapped.ts';
import NftRewardForm from './nft-reward-form';
import NtfRewardDetails from './ntf-reward-details';
import type NtfRewardItem from './types/ntf-reward-item';

type NftRewardProps = {
  tokensMetadata: TokenMetadataItem[];
  tokensSymbols: string[];
};

const NftReward: React.FunctionComponent<NftRewardProps> = (props: NftRewardProps) => {
  const params = useParams({ strict: false });

  const isExcludeOwnerCollectionFromStore: string | undefined =
    getFromLocalStorage('isExcludeOwnerCollection');
  const isExcludeNftSaleFromStore: string | undefined = getFromLocalStorage('isExcludeNftSale');

  const [isExcludeOwnerCollection, setIncludeOwnerCollection] = useState<boolean>(
    isExcludeOwnerCollectionFromStore !== undefined ? isExcludeOwnerCollectionFromStore === 'true' : true,
  );
  const [isExcludeNftSale, setIsExcludeNftSale] = useState<boolean>(
    isExcludeNftSaleFromStore !== undefined ? isExcludeNftSaleFromStore === 'true' : true,
  );
  const [collectionAddress, setCollectionAddress] = useState<string | undefined>(
    params?.collectionAddress || getFromLocalStorage('collectionAddress'),
  );
  const [distributeAmount, setDistributeAmount] = useState<string | undefined>(
    getFromLocalStorage('distributeAmount') || '100',
  );
  const [distributeToken, setDistributeToken] = useState<string | undefined>(
    getFromLocalStorage('distributeToken') || 'tsTON',
  );
  const [distributeMessage, setDistributeMessage] = useState<string | undefined>(
    getFromLocalStorage('distributeMessage') || 'NFT Reward',
  );
  const [ownerAddressFormat, setOwnerAddressFormat] = useState<string>(
    getFromLocalStorage('ownerAddressFormat') || 'DnsOrBase64',
  );
  const [distributeStrategy, setDistributeStrategy] = useState<string>(
    getFromLocalStorage('distributeStrategy') || ' ',
  );

  const ntfRewardReportResult = useQuery({
    queryKey: ['nft-reward'],
    queryFn: () => {
      if (collectionAddress && distributeAmount && distributeToken && distributeMessage) {
        return getNftRewardReport(
          collectionAddress,
          Number(distributeAmount),
          distributeToken,
          distributeMessage,
          '',
          isExcludeOwnerCollection,
          isExcludeNftSale,
          distributeStrategy,
          OwnerAddressFormat[ownerAddressFormat as keyof OwnerAddressFormat],
        );
      }
    },
    enabled: false,
  });

  const ntfRewardReportDownloadCSVResult = useQuery({
    queryKey: ['nft-reward-download'],
    queryFn: () => {
      if (collectionAddress && distributeAmount && distributeToken) {
        return getNftRewardReport(
          collectionAddress,
          Number(distributeAmount),
          distributeToken,
          distributeMessage,
          'csv',
          isExcludeOwnerCollection,
          isExcludeNftSale,
          distributeStrategy,
          OwnerAddressFormat[ownerAddressFormat as keyof OwnerAddressFormat],
        );
      }
    },
    enabled: false,
  });

  const ntfRewardReportDownloadMarkdownResult = useQuery({
    queryKey: ['nft-reward-download'],
    queryFn: () => {
      if (collectionAddress && distributeAmount && distributeToken) {
        return getNftRewardReport(
          collectionAddress,
          Number(distributeAmount),
          distributeToken,
          distributeMessage,
          'markdown',
          isExcludeOwnerCollection,
          isExcludeNftSale,
          distributeStrategy,
          OwnerAddressFormat[ownerAddressFormat as keyof OwnerAddressFormat],
        );
      }
    },
    enabled: false,
  });

  const onNftRewardReportDownload = (format: string) => {
    if (format === 'csv') {
      ntfRewardReportDownloadCSVResult.refetch();
    } else {
      ntfRewardReportDownloadMarkdownResult.refetch();
    }
  };

  useEffect(() => {
    if (collectionAddress && distributeAmount && distributeToken && distributeMessage) {
      ntfRewardReportResult.refetch();
    }
  }, [
    collectionAddress,
    distributeAmount,
    distributeToken,
    distributeMessage,
    distributeStrategy,
    ownerAddressFormat,
    isExcludeNftSale,
    isExcludeOwnerCollection,
  ]);

  const onInputChange = (name: string, value: string | number | boolean | undefined) => {
    switch (name) {
      case 'distributeStrategy': {
        setDistributeStrategy(value as string);
        setToLocalStorage(name, value as string);
        break;
      }
      case 'isExcludeOwnerCollection': {
        setIncludeOwnerCollection(value as boolean);
        setToLocalStorage(name, value as string);
        break;
      }
      case 'isExcludeNftSale': {
        setIsExcludeNftSale(value as boolean);
        setToLocalStorage(name, value as string);
        break;
      }
      case 'collectionAddress': {
        setCollectionAddress(value as string);
        setToLocalStorage(name, value as string);
        break;
      }
      case 'distributeAmount': {
        const valueElements: string[] = (value as any)?.split('.');
        if (valueElements?.length > 0) {
          const parsedValue: string | number =
            `${(valueElements[0] as any)?.replaceAll(/\D/gi, '')}${valueElements?.[1] > '' ? `.${(valueElements[1] as any)?.replaceAll(/\D/gi, '')}` : valueElements?.[1] === '' ? '.' : ''}`;
          setDistributeAmount(parsedValue);
          setToLocalStorage(name, parsedValue as string);
        }
        break;
      }
      case 'distributeToken': {
        setDistributeToken(value as string);
        setToLocalStorage(name, value as string);
        break;
      }
      case 'distributeMessage': {
        setDistributeMessage(value as string);
        setToLocalStorage(name, value as string);
        break;
      }
      case 'ownerAddressFormat': {
        setOwnerAddressFormat(value as OwnerAddressFormat);
        setToLocalStorage(name, value as string);
        break;
      }
      default: {
        break;
      }
    }
  };

  const {
    isFetching: isLoadingNftRewardReportData,
    data: ntfRewardReportData,
  }: { isFetching: boolean; data: any } = ntfRewardReportResult;
  const { isFetching: isLoadingNftRewardReportDownloadCSVData }: { isFetching: boolean } =
    ntfRewardReportDownloadCSVResult;
  const { isFetching: isLoadingNftRewardReportDownloadMarkdown }: { isFetching: boolean } =
    ntfRewardReportDownloadMarkdownResult;

  const ntfRewardReportItems: NtfRewardItem[] = ntfRewardReportData?.data
    ? ntfRewardReportMapped(ntfRewardReportData?.data)
    : [];

  const isLoading: boolean =
    isLoadingNftRewardReportData ||
    isLoadingNftRewardReportDownloadCSVData ||
    isLoadingNftRewardReportDownloadMarkdown;

  return (
    <FlexContainer flexDirection="column" alignItems="center">
      <NftRewardForm
        onInputChange={onInputChange}
        onNftRewardReportDownload={onNftRewardReportDownload}
        collectionAddress={collectionAddress}
        distributeAmount={distributeAmount}
        distributeToken={distributeToken}
        distributeMessage={distributeMessage}
        distributeStrategy={distributeStrategy}
        ownerAddressFormat={ownerAddressFormat}
        isLoading={isLoading}
        isExcludeOwnerCollection={isExcludeOwnerCollection}
        isExcludeNftSale={isExcludeNftSale}
        tokensSymbols={props.tokensSymbols}
        tokensMetadata={props.tokensMetadata}
      />
      <FlexContainer height={15} width="100%" flexDirection="column">
        {isLoading && <LinearProgress />}
      </FlexContainer>
      {ntfRewardReportItems?.length > 0 && <NtfRewardDetails ntfRewardReportItems={ntfRewardReportItems} />}
    </FlexContainer>
  );
};

export default NftReward;
