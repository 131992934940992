import './index.css';

import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { Dayjs } from 'dayjs';

type DatepickerProps = {
  name: string;
  value: Dayjs | undefined;
  onChange?: (value: Dayjs | null) => void;
  isError?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  minDate?: Dayjs | undefined;
  maxDate?: Dayjs | undefined;
};

const DatePicker: React.FunctionComponent<DatepickerProps> = (props: DatepickerProps) => {
  const onDateClean = () => {
    if (props?.onChange) {
      props.onChange(null);
    }
  };

  const onDateChange = (newDate: Dayjs | null) => {
    if (props?.onChange && newDate !== undefined) {
      props.onChange(newDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        format="DD.MM.YYYY"
        value={props.value}
        onChange={onDateChange}
        minDate={props?.minDate}
        maxDate={props?.maxDate}
        slotProps={{
          field: { clearable: true, onClear: () => onDateClean },
        }}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
