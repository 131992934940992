import type { SelectProps as MuiSelectProps } from '@mui/material/Select';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import type CSS from 'csstype';
import { DEFAULT_COMPONENT_HEIGHT, DEFAULT_COMPONENT_WIDTH } from '../../../constants';

type SelectProps = MuiSelectProps & {
  height?: string;
  width?: string;
  fontSize?: CSS.Property.FontSize;
};

const SelectStyled = styled(MuiSelect)();

const Select: React.FunctionComponent<SelectProps> = (props: SelectProps) => {
  return (
    <SelectStyled
      {...props}
      sx={{
        height: props?.height ?? DEFAULT_COMPONENT_HEIGHT,
        width: props?.width ?? DEFAULT_COMPONENT_WIDTH,
        fontSize: `${props?.fontSize ?? 'var(--main-font-size)'} !important`,
        '& > fieldset > legend': {
          opacity: 1,
          overflow: 'visible',
          visibility: 'visible',
          minWidth: '100px',
          fontFamily: 'var(--primary-font-family)',
          marginLeft: '10px',
          paddingLeft: '5px',
        },
        '& > fieldset > legend > span': {
          display: 'block',
          opacity: 1,
          visibility: 'visible',
          margin: '-3px 0 0 0',
          fontFamily: 'var(--primary-font-family)',
        },
        '@media (max-width: 768px)': {
          borderRadius: '21px',
        },
      }}
    >
      {props.children}
    </SelectStyled>
  );
};

export default Select;
