function accountsIdsParse(accountsIds: string): string[] {
  const accountsIdsParsed: string[] = accountsIds.split(',');
  if (accountsIdsParsed?.length > 1) {
    return accountsIdsParsed.map((accountId: string) => accountId?.trim());
  }
  const accountsIdsParsedLineBreaks: string[] = accountsIds?.split('\n');
  if (accountsIdsParsedLineBreaks?.length > 1) {
    return accountsIdsParsedLineBreaks.map((accountId: string) => accountId?.trim());
  }
  const accountsIdsParsedSpaces: string[] = accountsIds?.split(' ');
  console.log(accountsIdsParsedSpaces);
  return accountsIdsParsedSpaces;
}

export default accountsIdsParse;
