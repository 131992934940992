import MuiButton from '@mui/material/Button';
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsVariantOverrides, } from "@mui/material/Button/Button";

type ButtonProps = {
  children: React.ReactNode,
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>,
  type: any,
  onClick?: (evt: any) => void,
  disabled?: boolean,
}

const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  return (
    <MuiButton type={props.type} variant={props.variant} onClick={props?.onClick} disabled={props.disabled}>{props.children}</MuiButton>
  );
};

export default Button;