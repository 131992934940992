import { styled } from "styled-components";

const SwapContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 700px;
  width: 100dvw;
  @media (min-width: 767px) {
    max-width: 800px;    
  }
`;

export default SwapContainerStyled;
