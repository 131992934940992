import { toast } from 'react-toastify';
import { axiosInstance } from '../../../api/client-base.ts';
import InstanceType from '../../../enums/instance-type.ts';
import { fetchDocumentFromServerAxios } from '../../../helpers/fetch-document-from-server.ts';
import FilterMode from '../enums/filter-mode.ts';
import Format from '../enums/format.ts';
import OwnerAddressFormat from '../enums/owner-address-format.ts';

export const getNftRewardReport = async (
  collectionAddress: string,
  distributeAmount: number,
  distributeToken: string,
  distributeMessage: string = '',
  format: string,
  isExcludeOwnerCollection: boolean,
  isExcludeNftSale: boolean,
  distributeStrategy?: string,
  ownerAddressFormat?: OwnerAddressFormat,
): Promise<any> => {
  const options: any = {
    query: {
      collection_address: collectionAddress,
      collection_owner: isExcludeOwnerCollection ? FilterMode.Exclude : FilterMode.Include,
      nft_sale: isExcludeNftSale ? FilterMode.Exclude : FilterMode.Include,
    },
    distribute: {
      amount: distributeAmount,
      token: distributeToken,
      message: distributeMessage,
    },
    result: {
      format: format ? `${format === 'csv' ? Format.TonkeeperCsv : Format.TelegramMarkdown}` : Format.Json,
      address_format:
        ownerAddressFormat === OwnerAddressFormat.Name ? OwnerAddressFormat.DnsOrBase64 : ownerAddressFormat,
    },
  };

  if (distributeStrategy?.trim()) {
    options.distribute.strategy = distributeStrategy?.trim();
  }

  const response: any = await axiosInstance
    .post(`${InstanceType.Api}/tools/nft-reward/GetNftReport`, options)
    .catch((error: any) => {
      toast(error?.message, { type: 'error', draggable: true });
    });

  if (format) {
    return fetchDocumentFromServerAxios(
      response,
      'Nft reward report download file',
      'Error fft reward report download file',
      false,
      true,
      format === 'csv' ? `nft-report-${collectionAddress}.csv` : `nft-report-${collectionAddress}.md`,
    );
  }
  return response;
};

export const getNftCollectionMetadata = async (collectionAddress: string) => {
  return await axiosInstance({
    method: 'GET',
    url: `${InstanceType.Api}/nft/GetNftCollection?collection_address=${collectionAddress}`,
  });
};
