import {Table, TableBody, TableHead, TableRow as TableRowHeader} from "@mui/material";
import NtfRewardItem from "../types/ntf-reward-item.ts";
import TableHeaderCell from "../../common/table-header-cell";
import TableCell from '../../common/table-cell';
import TableRow from "../../common/table-row";
import NftRewardDetailsContainerStyled from "./nft-reward-details-container.styled.ts";

type NtfRewardDetailsProps = {
  ntfRewardReportItems: NtfRewardItem[]
}

const NtfRewardDetails: React.FunctionComponent<NtfRewardDetailsProps> = (props: NtfRewardDetailsProps) => {
  return <NftRewardDetailsContainerStyled>
    <Table>
      <TableHead>
        <TableRowHeader>
          <TableHeaderCell align="left">
            NftOwnerAddress
          </TableHeaderCell>
          <TableHeaderCell>
            Count
          </TableHeaderCell>
          <TableHeaderCell>
            Amount
          </TableHeaderCell>
          <TableHeaderCell>
            Token
          </TableHeaderCell>
          <TableHeaderCell>
            Message
          </TableHeaderCell>
        </TableRowHeader>
      </TableHead>
      <TableBody>
        {props.ntfRewardReportItems?.map((ntfRewardItem: NtfRewardItem) => (
          <TableRow key={ntfRewardItem.nftOwnerAddress}>
            <TableCell align="left">{ntfRewardItem.nftOwnerAddress}</TableCell>
            <TableCell>{ntfRewardItem.count}</TableCell>
            <TableCell>{ntfRewardItem.amount}</TableCell>
            <TableCell>{ntfRewardItem.token}</TableCell>
            <TableCell>{ntfRewardItem.comment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </NftRewardDetailsContainerStyled>;
};

export default NtfRewardDetails;