import round from 'lodash.round';

function formatNumber(value: string | number, numberOfDigitToRound: number | undefined = undefined, symbolDelimiter: string = ' '): string | null {
  let valueRounded: number = Number(value);
  if (valueRounded === 0) {
    return '0';
  }
  if (numberOfDigitToRound) {
    valueRounded = round(valueRounded, numberOfDigitToRound);
  }
  const valuePartitions: string[] | null = value ? String(valueRounded).split('.') : null;
  if (valuePartitions) {
    const valueParsed: string = String(valuePartitions[0]).replace(/(.)(?=(\d{3})+$)/g, `$1${symbolDelimiter}`);
    return `${valueParsed}${valuePartitions[1] ? `.${valuePartitions[1]}` : ''}`;
  }
  return null;
}

export default formatNumber;

