import styled from "styled-components";
import getMeasureValue from "../../../helpers/get-measure-value.ts";

type OperationIndicationProps = {
  isSendingOperation: boolean,
  fontSize?: number | string,
  fontWeight?: number
}


const OperationIndication = styled.span<OperationIndicationProps>`
  ${(props: OperationIndicationProps) => `  
  color: ${props.isSendingOperation ? 'var(--sending-operation-color)' : 'var(--recieving-operation-color)'};
  margin: 0;
  padding: 0;
  font-size: ${getMeasureValue(props.fontSize || '12px')};
  ${props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
  `}
`;


export default OperationIndication;