import CSS from 'csstype';
import styled from 'styled-components';

type ListItemContainerStyledProps = {
  color?: CSS.Property.Color;
};

const ListItemContainerStyled = styled.div<ListItemContainerStyledProps>`
  ${(props: ListItemContainerStyledProps) => `
    font-family: var(--primary-font-family);
    display: flex;
    line-height: 1;
    font-size: var(--main-font-size);
    @media (min-width: 767px) {
      border-bottom: 2px solid ${props.color};
    }
    @media (max-width: 767px) {
      border: none;
      border-radius: 5px;
      margin: 5px 0;
      justify-content: center;
      text-align: center;
      font-size: calc(var(--main-font-size) + 3px);
      background-color: ${props.color};
      padding: 3px;
    }  
  `}
`;

export default ListItemContainerStyled;
