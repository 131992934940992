// import {useEffect} from "react";
// import { createSwapWidget } from '@swap-coffee/ui-sdk';
// import { swapWidgetConfig } from './config'; // Configuration file
// import * as Props from "@tonconnect/ui";

const SwapWidget: React.FunctionComponent = () => {
  // const tonConnectUiInstance: any = useTonConnectUI();

  // useEffect(() => {
  //   const swapContainer: HTMLDivElement = document.createElement('div');
  //   swapContainer.id = 'swap-widget-component';
  //
  //   const swapWidgetContainer: Element | null = document.querySelector('#swap-widget-component-container');
  //   if (swapWidgetContainer) {
  //     swapWidgetContainer.appendChild(swapContainer);
  //   }
  // }, []);
  //
  // useEffect(() => {
  //
  //   const tonConnectUI = new Props.TonConnectUI({
  //     manifestUrl: 'https://mybit.money/tonconnect-manifest.json',
  //     buttonRootId: 'ton-connect'
  //   });
  //
  //   createSwapWidget('#swap-widget-component', {
  //     // Provide the container selector where the Swap Widget will be rendered
  //     ...swapWidgetConfig,  // Spread the existing Swap Widget configuration
  //
  //     // Pass the initialized TonConnectUI instance for TonConnect integration
  //     tonConnectUi: tonConnectUI,
  //   });
  // }, []);

  return (
    <>
      <iframe style={{ border: "none" }} width="90%" height="650" src="../../../widget.html" />
    </>
  );
};

export default SwapWidget;
